import React from "react";
import {
  Switch,
  Pagination,
  Input,
  Modal,
  PageHeader,
  Button,
  Form,
  Upload,
  Spin,
  Icon,
  Menu, Dropdown,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as constant from "../../actions/constant";
import {
  getUsers,
  deleteUser,
  deActivateUser,
  addUser,
  verifyUser,
  downloadCsv,
  downloadSampleCsv,
  mapUserToTopSport,
  disableUserToTopSport
} from "./actions/userActions";
import CustomModal from "../../components/common/CustomModal";
import { getBase64 } from "../../actions/constant";
import { ExportToCsv } from "export-to-csv";
import Papa from "papaparse";
const { confirm } = Modal;

const csvExportOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: false,
  title: "User CSV",
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  file_name: "users",
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      totalRecord: 0,
      search_string: "",
      userData: [],
      sortValue: "",
      sortOrder: "", //asc
      visible: false,
      imageUrl: "",
      fileObj: "",
      emailData: ""
    };
  }

  componentDidMount() {
    let obj = {
      search_string: this.state.search_string,
      page: this.state.currentPage,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
    };
    this.props.getUsers(obj);
  }

  searchString(e) {
    this.setState({
      search_string: e.target.value,
    });
    let obj = {
      search_string: e.target.value,
      page: this.state.currentPage,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
    };
    this.props.getUsers(obj);
  }

  changePage(page, pageSize) {
    this.setState({
      currentPage: page - 1,
    });
    let obj = {
      search_string: this.state.search_string,
      page: page - 1,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
    };
    this.props.getUsers(obj);
  }

  sortData(sortVal) {
    this.setState({
      sortOrder: this.state.sortOrder === -1 ? 1 : -1,
    });
    let obj = {
      search_string: this.state.search_string,
      page: this.state.currentPage,
      sortValue: sortVal,
      sortOrder: this.state.sortOrder === -1 ? 1 : -1,
    };
    this.props.getUsers(obj);
  }

  showConfirm(userId) {
    let self = this;
    let obj = {
      userId: userId,
    };
    confirm({
      title: constant.DELETE_RECORD,
      content: "",
      onOk() {
        self.props.deleteUser(obj);
      },
      // onCancel() { },
    });
  }

  showVerificationConfirm(userId) {
    let self = this;
    let obj = {
      userId: userId,
    };
    confirm({
      title: constant.VERIFY_RECORD,
      content: "",
      onOk() {
        self.props.verifyUser(obj);
      },
      // onCancel() { },
    });
  }

  deActivateUser(userId) {
    let values = {
      userId: userId,
    };
    this.props.deActivateUser(values);
  }

  componentWillReceiveProps(nextProps) {
    let data = nextProps.userData;
    let csvData = nextProps.csvData;
    if (csvData) {
      const csvExporter = new ExportToCsv(csvExportOptions);
      csvData.forEach((v) => {
        return delete v._id;
      });
      csvExporter.generateCsv(csvData);
    }
    this.setState({
      userData: data,
      totalRecord: nextProps.totalCount,
      loading: false,
      csvData,
    });

  }
  showModal = (id, name, description) => {
    this.setState({
      visible: true,
      bonusId: id,
    });
    this.props.form.setFieldsValue({
      bonusname: name,
      description: description,
    });
  };

  // static getDerivedStateFromProps(props, state) {
  //     if(state.csvData) {
  //         console.log(this.state.csvData)
  //     }

  //     return {};
  // }
  exportCsv = () => {
    this.setState({ loading: true });
    this.props.downloadCsv();
  };

  disableUser = () => {
    this.props.disableUserToTopSport()

    let obj = {
      page: this.state.currentPage,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
    };
    this.props.getUsers(obj);
  }

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          fileObj: info.file.originFileObj,
          changeImage: true,
        })
      );
    }
  };

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      console.log("value", values, this.state.fileObj);
      if (!err) {
        this.setState({ visible: false });
        let formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("file", this.state.fileObj);
        this.props.addUser(formData);
      } else {
      }
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  uploadCsv = (e) => {

    let file = e.target.files[0];

    let parsedData = Papa.parse(file, {
      header: true,
      complete: results => {
        let res = results.data;

        let emailData = res.map(e => e.email);
        if (emailData.length) {
          this.setState({ loading: true });
          let obj = {
            search_string: this.state.search_string,
            page: this.state.currentPage,
            sortValue: this.state.sortValue,
            sortOrder: this.state.sortOrder,
            emails: emailData
          };
          this.props.mapUserToTopSport(obj)
        } else if(emailData.length) {
          this.setState({ loading: true });
          let obj = {
            search_string: this.state.search_string,
            page: this.state.currentPage,
            sortValue: this.state.sortValue,
            sortOrder: this.state.sortOrder,
            emails: emailData
          };
          this.props.mapUserToTopSport(obj)
        }
      },
    });
 
  };
  
  
  render() {
    const { getFieldDecorator } = this.props.form;
    let state = this.state;
    let profilePic = state.imageUrl ? state.imageUrl : "";

    const users = state.userData.map((x, index) => {
      if (x.role.rolename == "user" || x.role.rolename == "kingbot") {
        return (
          <tr key={index}>
            <td>{x.name}</td>
            <td>{x.email}</td>
            <td>{x.country}</td>
            <td>{x.isTopSportUser ? "Yes" : "No"}</td>
            {/* <td>{x.address ? x.address : '-'}</td> */}
            <td>
              {" "}
              <Switch
                checked={x.isActive}
                onChange={this.deActivateUser.bind(this, x._id)}
              />
            </td>
            <td className="action-icons">
              {" "}
              <Link to={"/userprofile/" + x._id}>
                {" "}
                <Button type="link" className="btn-sm mr-2">
                <Icon type="eye" theme="filled" />
                </Button>
              </Link>
              {x.role.rolename == "user" ? (
                <Button type="link" className=" btn-sm mr-2"
                  onClick={this.showConfirm.bind(this, x._id)}
                >
                 <Icon type="delete" theme="filled" />
                </Button>
              ) : null}
              {!x.isVerified ? (
                <Button type="link" className="btn-sm"
                  onClick={this.showVerificationConfirm.bind(this, x._id)}
                >
                <i class="fa fa-envelope" aria-hidden="true"></i>
                </Button>
              ) : null}
            </td>
          </tr>
        );
      }
    });
    const menu = (
      <Menu>
        <Menu.Item onClick={this.disableUser.bind(this)}>Disable User</Menu.Item>
        <Menu.Item>
          <a href="/sample_file.csv" target="_blank" className="clr-inhrit">Sample File to Import</a>
        </Menu.Item>
        <Menu.Item>
          <label class="custom-file-uploaded mb-0">
            <input class="map-users" type={"file"} name="file" onChange={this.uploadCsv.bind(this)} />
            Map Users to TopSport
          </label>
        </Menu.Item>
        <Menu.Item>
          <div
            key="2"
            onClick={this.exportCsv.bind(this)}
          >
            Export CSV
          </div>
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        <Spin spinning={this.state.loading} delay={500}>
          <h2 className="page-heading">Manage User</h2>
          <div className="box box-default mb-4">
            
            {/* <PageHeader
              title="USERS"
              subTitle=""
              extra={[ */}
                {/* <Input
                  placeholder="Search"
                  onChange={this.searchString.bind(this)}
                  key="search"
                  style={{ width: "auto" }}
                />, */}
                {/* // <Button
                //   type="primary"
                // >
                //   <a href="/sample_file.csv" target="_blank" >Sample File to Import</a>
                // </Button>,

                // <label class="custom-file-upload ant-btn ant-btn-primary">
                //   <input class="map-users" type={"file"} name="file" onChange={this.uploadCsv.bind(this)} />
                //   <Icon type="upload" /> Map Users to TopSport
                // </label>
                // ,
                // <Button
                //   key="2"
                //   type="primary"
                //   onClick={this.exportCsv.bind(this)}
                // >
                //   Export CSV
                // </Button>, */}
                
              {/* ]}
            /> */}
            <div className="box-body">
            <Row className="mb-4">
              <Col xs={9} sm={6}>
              <Input prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Search"
                  onChange={this.searchString.bind(this)}
                  key="search"
                  style={{ width: "100%" }} />
              </Col>
              <Col xs={3} sm={6} className="text-right">
              <Dropdown overlay={menu} trigger={['click']}>
                  <a className="muser-dropdown-link" onClick={e => e.preventDefault()}>
                    <Icon type="menu-unfold" />
                  </a>
                </Dropdown>
              </Col>
            </Row>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      onClick={this.sortData.bind(this, "name")}
                      className="cursor-pointer"
                    >
                      Name{" "}
                      {/* {this.state.sortOrder === 1 ? (
                        <i
                          className="fa fa-sort-amount-asc"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-sort-amount-desc"
                          aria-hidden="true"
                        ></i>
                      )} */}
                    </th>
                    <th scope="col">Email</th>
                    <th scope="col">Country</th>
                    <th scope="col">TopSport</th>
                    {/* <th scope="col">Address</th> */}
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>{users}</tbody>
              </table>
              </div>
              <Pagination
                style={{ textAlign: "right" }}
                pageSize={10}
                defaultCurrent={this.state.currentPage}
                onChange={this.changePage.bind(this)}
                total={this.state.totalRecord ? this.state.totalRecord : 1}
              />
              <CustomModal
                handleCancel={this.handleCancel}
                handleOk={this.handleOk}
                visible={this.state.visible}
                title="User"
                backBtnText="Back"
                submitBtnText="Submit"
              >
                <Form className="team-form l_hgt0">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    onChange={this.handleChange}
                  >
                    <img
                      src={
                        profilePic ? profilePic : "http://placehold.it/380x500"
                      }
                      alt="profile"
                      className="img-rounded img-responsive img-104"
                    />
                  </Upload>
                  <Form.Item label="User Name">
                    {getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter name",
                        },
                      ],
                    })(<Input placeholder="User Name" />)}
                  </Form.Item>
                  <Form.Item label="Email Id">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter email id",
                        },
                      ],
                    })(<Input placeholder="Email Id" />)}
                  </Form.Item>
                  <Form.Item label="Password">
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter Password",
                        },
                      ],
                    })(<Input placeholder="Password" />)}
                  </Form.Item>
                </Form>
              </CustomModal>
            </div>
          </div>
        </Spin>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    csvData: state.user.csvData,
    userData: state.user.userData,
    totalCount: state.user.totalCount,
  };
}
const Userss = Form.create({ name: "users" })(Users);
export default connect(mapStateToProps, {
  getUsers,
  deleteUser,
  deActivateUser,
  addUser,
  verifyUser,
  downloadCsv,
  mapUserToTopSport,
  disableUserToTopSport
})(Userss);

import React from "react";
import {
    Pagination,
    Button,
    Modal,
    Descriptions,
    PageHeader,
    Form,
    Input,
    Spin,
    Icon,
    Select,
    Switch,
    DatePicker,
    InputNumber,
} from "antd";
import CustomModal from "../../../components/common/CustomModal";
import * as constant from "../../../actions/constant";
import {
    getRound,
    getSport,
    removeRound,
    addRound,
    blockRound,
    updateRound,
    getSeason,
} from "../../admin/actions/adminActions";
import { connect } from "react-redux";
import moment from "moment";
import toastr from "toastr";
const { RangePicker } = DatePicker;

const { confirm } = Modal;
const { Option } = Select;

class RoundList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            totalRecord: 0,
            visible: false,
            sportData: [],
            roundData: [],
            roundTypeData: [
                { id: 1, name: "Regular" },
                { id: 2, name: "Playoffs" },
            ],
            sportId: "",
            roundId: "",
            isRoundUpdate: false,
            sortValue: "",
            sortOrder: "",
            loading: true,
            seasonData: [],
            seasonId: "",
            seasonId2: "",
            tchangePage: "",
            RoundseasonData: "",
            RoundSportdata: "",
            isDateEdit: true,
            validate:{}
        };
    }

    componentDidMount() {
        let obj = {
            page: this.state.currentPage,
            sortValue: this.state.sortValue,
            sortOrder: this.state.sortOrder,
        };
        this.props.getRound(obj);
        let obj1 = {
            count: 1000,
            isActive: true
        };
        this.props.getSport(obj1);
        let SeasonObj = {
            isActive: true,
        };

        this.props.getSeason(SeasonObj);
    }

    showModal = (
        id,
        roundno,
        roundname,
        roundtype,
        sport,
        startDate,
        endDate,
        season
    ) => {
        this.handelEditDate(sport, roundno,startDate);
        this.setState({
            visible: true,
            roundId: id,
            sportId: sport._id,
            RoundseasonData: season,
            RoundSportdata: sport
        });
        if (id !== "") {
            this.setState({
                isRoundUpdate: true,
            });
            this.props.form.resetFields();
            this.props.form.setFieldsValue({
                roundno: roundno,
                roundname: roundname,
                roundtype: roundtype,
                sport: sport._id,
                date: [moment(new Date(startDate)), moment(new Date(endDate))],
            });
        } else {
            // var sDate = new Date();
            // sDate.setDate(sDate.getDate() + ((7 - sDate.getDay()) % 7) + 1);
            // var eDate = new Date(sDate);
            // eDate.setDate(eDate.getDate() + ((6 - eDate.getDay()) % 6) + 1);

            this.setState({
                isRoundUpdate: false,
                isDateEdit:false
            });
            this.props.form.resetFields();
            this.props.form.setFieldsValue({
                roundno: 1,
                date: "",
            });
        }
    };

    handleMenuClick(value) {
        console.log("value", value);
        let thiss = this;
        thiss.setState({
            sportId: value,
        });

        this.setFieldsValue(value);
    }
    handleRoundTypeClick(value) {
        console.log("value", value);
        let thiss = this;
        thiss.setState({
            roundtype: value,
        });
    }

    setFieldsValue(sportId) {
        if (sportId) {
            var rounds = this.state.roundData;
            var sportRounds = rounds.filter((round) => round.sport._id == sportId);

            if (sportRounds.length > 0) {
                if (sportRounds.length > 1) {
                    sportRounds = sportRounds.sort((a, b) => a.roundno - b.roundno);
                }
                var round = sportRounds[sportRounds.length - 1];
                var sDate = new Date(round.startDate);
                sDate.setDate(sDate.getDate() + ((7 - sDate.getDay()) % 7) + 1);
                var eDate = new Date(sDate);
                eDate.setDate(eDate.getDate() + ((6 - eDate.getDay()) % 6) + 1);

                this.props.form.setFieldsValue({
                    roundno: round.roundno + 1,
                    date: [moment(sDate), moment(eDate)],
                });
            } else {
                var sDate = new Date();
                sDate.setDate(sDate.getDate() + ((7 - sDate.getDay()) % 7) + 1);
                var eDate = new Date(sDate);
                eDate.setDate(eDate.getDate() + ((6 - eDate.getDay()) % 6) + 1);
                this.props.form.setFieldsValue({
                    roundno: 1
                });
            }
        }
    }


    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.state.isRoundUpdate) {
                    let obj = {
                        roundId: this.state.roundId,
                        // roundtype: this.state.roundtype,
                        // roundno: values.roundno,
                        roundname: values.roundname,
                        // sportId: this.state.sportId,
                        // startDate: values.startDate,
                        // endDate: values.endDate
                        startDate: values.date[0],
                        endDate: values.date[1],
                        
                    };
                    this.props.updateRound(obj);
                    this.setState({ isRoundUpdate: false, visible: false });
                } else {
                    let obj = {
                        roundno: values.roundno,
                        roundname: values.roundname,
                        roundtype: this.state.roundtype,
                        sportId: this.state.sportId,
                        startDate: values.date[0],
                        endDate: values.date[1],
                    };
                    let startDate = new Date(values.date[0]);
                    let endDate = new Date(values.date[1]);
                    let seasonstartDate = "";
                    let seasonendDate = "";
                    {
                        this.state.sportData.map((x, index) => {
                            if (x._id == this.state.sportId) {
                                let seasonStart = new Date(x.startDate);
                                let seasonEnd = new Date(x.endDate);
                                seasonstartDate = seasonStart.getTime();
                                seasonendDate = seasonEnd.getTime();
                            }
                        });
                    }


                    // console.log("First condtion : " + seasonstartDate <= startDate.getTime())
                    // console.log("Second condtion : " + endDate.getTime() <= seasonendDate)
                    // console.log(seasonstartDate + " startDate is-------->", startDate.getTime());
                    // console.log(seasonendDate + " endDate is-------->", endDate.getTime());

                    if (
                        seasonstartDate <= startDate.getTime() &&
                        endDate.getTime() < seasonendDate
                    ) {
                        this.props.addRound(obj);
                        // toastr.success("it's proper data")
                        // console.log("this is on=j", obj);
                        this.setState({ visible: false, currentPage: 0 });
                    } else {
                        toastr.warning("Please select date between sport date");
                    }
                }
            } else {
            }
        });
    };

    blockRound(roundId) {
        let values = {
            roundId: roundId,
        };
        this.props.blockRound(values);
    }

    handleCancel = () => {
        this.setState({ visible: false });
    };

    showConfirm(roundId) {
        let self = this;
        let obj = {
            roundId: roundId,
        };
        confirm({
            title: constant.DELETE_RECORD,
            content: "",
            onOk() {
                self.setState({ currentPage: 0 });
                self.props.removeRound(obj);
            },
            onCancel() { },
        });
    }

    changePage(page, pageSize) {
        this.setState({
            currentPage: page - 1,
        });
        let obj = {
            page: page - 1,
            sortValue: this.state.sortValue,
            sortOrder: this.state.sortOrder,
            sportId: this.state.sportId
        };
        this.props.getRound(obj);
    }

    sortData(sortVal) {
        this.setState({
            sortOrder: this.state.sortOrder === -1 ? 1 : -1,
        });
        let obj = {
            page: this.state.currentPage,
            sortValue: sortVal,
            sortOrder: this.state.sortOrder === -1 ? 1 : -1,
        };
        this.props.getRound(obj);
    }

    componentWillReceiveProps(nextProps) {
        let data = nextProps.roundData;
        this.setState({
            roundData: data,
            totalRecord: nextProps.totalCount,
            loading: false,
        });
        let sportdata = nextProps.sportData;
        this.setState({ sportData: sportdata, loading: false });
        let sesData = nextProps.seasonData;
        this.setState({ seasonData: sesData });
        sesData.map((x, index) =>
            this.state.seasonId.includes(`${x._id}`)
                ? ""
                : this.setState({
                    seasonId: x._id,
                })
        );
    }

    handleMenuClick1(values) {
        // console.log("values isss", values);
        let obj = {
            seasonId: values,
        };
        console.log("obj obj ", obj);
        this.setState({
            seasonId: values,
        });
        if (obj.seasonId == "") {
            let obj1 = {
                page: this.state.currentPage,
                sortValue: this.state.sortValue,
                sortOrder: this.state.sortOrder,
            };
            this.props.getSport(obj1);
        } else {
            this.props.getSport(obj);
        }
    }

    handleMenuClickSeason(values) {
        let obj = {
            seasonId: values,
        };
        console.log("obj obj =======", obj);
        this.setState({
            seasonId2: values,
        });
        if (obj.seasonId == "") {
            let obj1 = {
                page: this.state.currentPage,
                sortValue: this.state.sortValue,
                sortOrder: this.state.sortOrder,
            };
            this.props.getSport(obj1);
        } else {
            this.props.getSport(obj);
        }
    }

    handleMenuClickSport(values) {
        let obj = {
            sportId: values,
        };
        this.setState({
            sportId: values
        })
        let obj1 = {
            page: this.state.currentPage,
            sortValue: this.state.sortValue,
            sortOrder: this.state.sortOrder,
        };
        console.log("dataaaaa", obj);
        if (obj.sportId == "") {
            this.props.getRound(obj1);
        } else {
            this.props.getRound(obj);
        }
    }


    handelEditDate(sportId, roundNo,roundStartDate) {
        if (sportId) {
            let today = new Date()
            let sportendDate = new Date(sportId?.endDate)
            let roundStart=new Date(roundStartDate)
            
            if (sportendDate >= today&roundStart>=today) {
                // console.log("sportId?.endDate>today", sportendDate >= today&roundStart>=today)
                this.setState({
                    isDateEdit: false
                })
                var rounds = this.state.roundData;
                var sportRounds = rounds.filter((round) => round.sport._id === sportId._id);
                if(sportRounds.length>0){
                    sportRounds = sportRounds.sort((a, b) => a.roundno - b.roundno);
                    let foundLower = false;
                    let foundGreater = false;
                    const filteredArray = sportRounds.filter((item) => {
                      if (!foundLower && item.roundno < roundNo) {
                        foundLower = true;
                        return true;
                      }
                      if (!foundGreater && item.roundno > roundNo) {
                        foundGreater = true;
                        return true;
                      }
                      return false;
                    });
                     let roundEndDate= new Date(filteredArray[0]?.endDate)
                    let startDate=filteredArray[0]?.endDate?filteredArray[0].endDate:today
                    if(roundEndDate<today){
                        startDate=today
                    }
                    let endDate=filteredArray[1]?.endDate?filteredArray[1].startDate:sportId.endDate
                    this.setState({
                        validate:{
                            startDate,
                            endDate
                        }
                    })
                    
                }
    
    
            }else{
                this.setState({
                    isDateEdit: true
                })

            }
        }


    }
    render() {
        const { getFieldDecorator } = this.props.form;
        let state = this.state;

        const sport = state.sportData.map((x, index) => (
            <Option value={x._id}>{x.sportname}</Option>
        ));

        const round = state.roundData.map((x, index) => (
            <tr key={index}>
                <td>{x.roundno}</td>
                <td>{x.roundname}</td>
                <td>{x.roundtype}</td>
                <td>{x.sport.sportname}</td>
                {/* {console.log("round Dataaa", x)} */}
                {/*<td><Switch checked={x.isActive} onChange={this.blockRound.bind(this, x._id)} /></td>*/}
                <td className="action-icons">
                    <Button
                        className="btn-sm mr-2" type="link"
                        onClick={() => {
                            this.showModal(
                                x._id,
                                x.roundno,
                                x.roundname,
                                x.roundtype,
                                x.sport,
                                x.startDate,
                                x.endDate,
                                x.season
                            );

                        }}
                    >
                        <i className="fa fa-pencil"></i>
                    </Button>
                    <Button
                        className="btn-sm mr-2" type="link"
                        onClick={this.showConfirm.bind(this, x._id)}
                    >
                        <Icon type="delete" theme="filled" />
                    </Button>
                </td>
            </tr>
        ));

          const startDate = moment(this.state.validate.startDate);
           const endDate = moment(this.state.validate.endDate);
           const disabledDate = current => {
           
            return current && (current <= startDate || current >= endDate&current>=new Date());
          };
          



        return (
            <>
                <div className="box box-default mb-4">
                    <PageHeader
                        title="Round"
                        subTitle=""
                        extra={[
                            <Select defaultValue="" placeholder="Season" style={{ width: 150 }}
                                onChange={this.handleMenuClickSport.bind(this)} className="mobile-bottom-space"
                            >
                                <   Option value="" >All</Option>
                                {
                                    sport
                                }
                            </Select>,
                            // <Select defaultValue="" placeholder="Sport" style={{ width: 150 }}
                            //     onChange={this.handleMenuClickSeason.bind(this)}
                            // >
                            //     <   Option value='' >Season</Option>
                            //     {
                            //         state.seasonData.map((x, index) =>
                            //             <   Option value={x._id} key={index}>{x.seasonname}</Option>
                            //         )
                            //     }
                            // </Select>,

                            <Button
                                key="1"
                                type="outline"
                                onClick={this.showModal.bind(this, "", "", "", "")}
                            ><Icon type="plus" className="mr-1" />
                                Add Round
                            </Button>

                        ]}
                    />
                    <Spin spinning={this.state.loading} delay={500}>
                        <div className="box-body">
                            <div className='table-responsive'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                onClick={this.sortData.bind(this, "roundno")}
                                                className="cursor-pointer"
                                            >
                                                Round No{" "}
                                                {this.state.sortOrder === 1 ? (
                                                    <i
                                                        className="fa fa-sort-amount-asc"
                                                        aria-hidden="true"
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-sort-amount-desc"
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </th>

                                            <th
                                                scope="col"
                                                onClick={this.sortData.bind(this, "roundname")}
                                                className="cursor-pointer"
                                            >
                                                Round Name{" "}
                                                {this.state.sortOrder === 1 ? (
                                                    <i
                                                        className="fa fa-sort-amount-asc"
                                                        aria-hidden="true"
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-sort-amount-desc"
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </th>
                                            <th
                                                scope="col"
                                                onClick={this.sortData.bind(this, "roundtype")}
                                                className="cursor-pointer"
                                            >
                                                Type{" "}
                                                {this.state.sortOrder === 1 ? (
                                                    <i
                                                        className="fa fa-sort-amount-asc"
                                                        aria-hidden="true"
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-sort-amount-desc"
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </th>
                                            <th
                                                scope="col"
                                                onClick={this.sortData.bind(this, "sport.sportname")}
                                                className="cursor-pointer"
                                            >
                                                Sports{" "}
                                                {this.state.sortOrder === 1 ? (
                                                    <i
                                                        className="fa fa-sort-amount-asc"
                                                        aria-hidden="true"
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-sort-amount-desc"
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </th>
                                            {/*<th scope="col">Status</th>*/}
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {console.log(this.state.RoundseasonData)} */}
                                        {round}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                style={{ textAlign: "right" }}
                                pageSize={10}
                                current={this.state.currentPage + 1}
                                defaultCurrent={this.state.currentPage + 1}
                                total={this.state.totalRecord ? this.state.totalRecord : 1}
                                onChange={this.changePage.bind(this)}
                            />
                            <CustomModal
                                handleCancel={this.handleCancel}
                                handleOk={this.handleOk}
                                visible={this.state.visible}
                                title="Round"
                                backBtnText="Back"
                                submitBtnText="Submit"
                            >
                                <Form className="sport-form l_hgt0">
                                    {/* {state.isRoundUpdate == true ? (
                                        state.RoundseasonData == "" ||
                                            state.RoundSportdata == undefined ? (
                                            <Descriptions
                                                initialValue
                                                title="Sport Info :"
                                                layout="vertical"
                                            >
                                                <Descriptions.Item label="Sport Name">
                                                    {"Data not found"}{" "}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Start Date">
                                                    {"Data not found"}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="End Date">
                                                    {"Data not found"}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        ) : (
                                            <Descriptions
                                                initialValue
                                                title="Sport Info :"
                                                layout="vertical"
                                            >
                                                <Descriptions.Item label="Sport Name">
                                                    {state.RoundSportdata.sportname}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Start Date">
                                                    {new Date(
                                                        state.RoundSportdata.startDate
                                                    ).toDateString()}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="End Date">
                                                    {new Date(
                                                        state.RoundSportdata.endDate
                                                    ).toDateString()}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        )
                                    ) : (
                                        state.sportData.map((x, index) => {
                                            if (x._id == this.state.sportId) {
                                                return (<Descriptions
                                                    initialValue
                                                    title="Sport Info :"
                                                    value={x._id}
                                                    key={index}
                                                    layout="vertical"
                                                >
                                                    <Descriptions.Item label="Sport Name">
                                                        {x.sportname}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Start Date">
                                                        {x.startDate}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="End Date">
                                                        {x.endDate}
                                                    </Descriptions.Item>
                                                </Descriptions>)
                                            }
                                        })
                                    )} */}
                                    <Form.Item label="Select Sport">
                                        {getFieldDecorator("sport", {
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please select sport",
                                                },
                                            ],
                                            initialValue: this.state.isRoundUpdate ? ["sport"] : null,
                                        })(
                                            <Select
                                                disabled={this.state.isRoundUpdate}
                                                onChange={this.handleMenuClick.bind(this)}
                                            >
                                                {sport}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Round No">
                                        {getFieldDecorator("roundno", {
                                            rules: [
                                                { required: true, message: "Please enter round no" },
                                            ],
                                        })(<InputNumber disabled={this.state.isRoundUpdate} placeholder="Round No" />)}
                                    </Form.Item>
                                    <Form.Item label="Round Name">
                                        {getFieldDecorator("roundname", {
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please enter round name",
                                                },
                                            ],
                                        })(<Input placeholder="Round Name" />)}
                                    </Form.Item>
                                    <Form.Item label="Select Type">
                                        {getFieldDecorator("roundtype", {
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please select Type",
                                                },
                                            ],
                                            initialValue: this.state.isRoundUpdate
                                                ? ["roundtype"]
                                                : null,
                                        })(
                                            <Select
                                                disabled={this.state.isRoundUpdate}
                                                onChange={this.handleRoundTypeClick.bind(this)}
                                            >
                                                {this.state.roundTypeData.map((x, index) => (
                                                    <Option value={x.name} key={index}>
                                                        {x.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Select start date and end date">
                                        {getFieldDecorator("date", {
                                            rules: [
                                                { required: true, message: "Please enter date range" },
                                            ],
                                        })(
                                            <RangePicker
                                                disabledDate={this.state.isRoundUpdate?disabledDate:null}
                                                disabled={this.state.isDateEdit}
                                                placeholder="Date"
                                            />
                                        )}
                                    </Form.Item>
                                </Form>
                            </CustomModal>
                        </div>
                    </Spin>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        roundData: state.admin.roundData,
        totalCount: state.admin.roundCount,
        sportData: state.admin.sportData,
        seasonData: state.admin.seasonData,
    };
}
const Round = Form.create({ name: "round" })(RoundList);
export default connect(mapStateToProps, {
    getRound,
    addRound,
    removeRound,
    getSport,
    blockRound,
    updateRound,
    getSeason,
})(Round);

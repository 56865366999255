const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

const baseURLs = {
    local : 'http://localhost:3019/',
    live : 'https://api-live.toptippers.com/',
    staging : 'http://54.201.160.69:9194/',
}


export const BASE_URL = baseURLs[process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'live'] 

//Staging
//export const BASE_URL = 'http://54.201.160.69:3019/'

//Live
//export const BASE_URL = 'http://toptippers.com:3019/'

export const API_URL = BASE_URL + 'api/'
export const PROFILE_IMG_PATH = BASE_URL + 'app/uploads/profile/';
export const LOGO_IMG_PATH = BASE_URL + 'app/uploads/teamlogo/';

export function actionCreator(actionType, data) {
    return {
        type: actionType,
        payload: data,
    };
}

export function createRequestActionTypes(base) {
    return [REQUEST, SUCCESS, FAILURE].reduce((requestTypes, type) => {
        requestTypes[type] = `${base}_${type}`;
        return requestTypes;
    }, {});
}

export const jsonApiHeader = (accessToken, ContentType) => {
    return {
        "Content-Type": ContentType,
        Authorization: localStorage.access_token ?
            `${localStorage.access_token}` : "",
    };
};
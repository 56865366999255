
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Icon, Input, Button, Checkbox, Upload, message, Card } from 'antd';
const props = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
class ChangePassword extends Component {



  render() {
    return (
      <div className="profile-container">
        <Container>
          <Row>
            <Col>
            <h2 className='page-heading'>Manage Password</h2>
              <Card>
                <Form className="profile-form">
                  <Row>
                    <Col sm={6}>
                      <Form.Item label="New Password">
                        <Input placeholder="Enter New Password" />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item label="Confirm Password">
                        <Input placeholder="Confirm Password" />
                      </Form.Item>
                    </Col>
                  </Row>
               
                  <Row>
                    <Col>
                      <Form.Item className="gx-text-center">
                        <Button type="primary"  htmlType="submit">
                          Update
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>

      </div>);
  }
}



export default ChangePassword;

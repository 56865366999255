import React from "react";
import {
  Button,
  PageHeader,
  Modal,
  Form,
  Pagination,
  Switch,
  Input,
  Icon,
  Spin,
  Radio,
  Checkbox,
} from "antd";
import CustomModal from "../../components/common/CustomModal";
import { connect } from "react-redux";
import * as constant from "../../actions/constant";
import {
  getVersion,
  addVersion,
  removeVersion,
} from "../admin/actions/adminActions";
import toastr from "toastr";
const { TextArea } = Input;
const { confirm } = Modal;
class Version extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      isRequiredValue: "",
      currentPage: 0,
      sortValue: "",
      sortOrder: "",
      versionData: [],
      versionCount: 0,
      radioArray: [
        { id: 1, name: "Required", value: true },
        { id: 2, name: "Not Required", value: false },
      ],
      platformArray: [
        { id: 1, name: "Android", value: "Android" },
        { id: 2, name: "iOS", value: "iOS" },
      ],
      versionId: "",
      platformValue : '',
    };
  }
  showModal(id, title, description, version, platform, isRequired) {
    this.setState({
      visible: true,
      versionId: id,
    });
    if (id !== "") {
      console.log("id");
      this.props.form.resetFields();
      this.props.form.setFieldsValue({
        title: title,
        description: description,
        version: version,
        platform: platform,
        isrequired : isRequired,
      });
      this.setState({  versionId: id });
    } else {
      this.props.form.resetFields();
    }
  }

  componentDidMount() {
    let obj = {
      page: this.state.currentPage,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
    };
    this.props.getVersion(obj);
  }
  componentWillReceiveProps(nextProps) {
    let data = nextProps.versionData;
    this.setState({
      versionData: data,
      versionCount: nextProps.versionCount,
      loading: false,
    });
  }
  handleCancel = () => {
    this.setState({ visible: false });
    this.props.form.resetFields();
  };


  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let obj = {
          title: values.title,
          description: values.description,
          version: values.version,
          platform: values.platform,
          isRequired: values.isrequired,
          versionId: this.state.versionId,
        };

        this.setState({ visible: false });
        this.props.addVersion(obj);
        this.props.form.resetFields();
      }
    });
  };

  onChange = (e) => {
    this.setState({ isRequiredValue: e.target.value });
  };
  onChangePlatform = (e) => {
    this.setState({ isRequiredValue: e.target.value });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  changePage(page, pageSize) {
    this.setState({
      currentPage: page - 1,
    });
    let obj = {
      page: page - 1,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
    };
    this.props.getVersion(obj);
  }

  showConfirm(id) {
    let self = this;
    let obj = {
      versionId: id,
    };
    confirm({
      title: constant.DELETE_RECORD,
      content: "",
      onOk() {
        self.props.removeVersion(obj);
      },
      onCancel() {},
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let state = this.state;

    const version = state.versionData.map((x, index) => (
      <tr key={index}>
        <td>{x.title}</td>
        <td>{x.description}</td>
        <td>{x.version}</td>
        <td>{x.platform}</td>
        <td>
          {x.isRequired == true ? (
            <Checkbox checked={true} />
          ) : (
            <Checkbox checked={false} />
          )}
        </td>
        <td className="action-icons">
          <Button type="link"
            className="btn-sm mr-2"
            onClick={this.showModal.bind(
              this,
              x._id,
              x.title,
              x.description,
              x.version,
              x.platform,
              x.isRequired,
            )}
          >
            <i className="fa fa-pencil"></i>
          </Button>
          <Button type="link"
            className="btn-sm mr-2"
            onClick={this.showConfirm.bind(this, x._id)}
          >
            <i className="fa fa-trash"></i>
          </Button>

          {/* <Button className="btn btn-info btn-sm mr-2" onClick={this.showConfirmResetPoints.bind(this, x._id)}><i className="fa fa-anchor"></i></Button> */}
        </td>
      </tr>
    ));

    return (
      <>
        <div className="box box-default mb-4">
          <PageHeader
            title="Version"
            subTitle=""
            extra={[
              <Button
                type="outline"
                onClick={this.showModal.bind(this, "", "")}
                style={{
                  float: "right",
                }}
              >
                 <Icon type="plus" className="mr-1" />
                Add Version
              </Button>,
            ]}
          />
          <Spin spinning={this.state.loading} delay={500}>
            <div className="box-body">
              <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" className="cursor-pointer">
                      Title{" "}
                    </th>
                    <th scope="col" className="cursor-pointer">
                      Description{" "}
                    </th>
                    <th scope="col" className="cursor-pointer">
                      Version
                    </th>
                    <th scope="col" className="cursor-pointer">
                      Platform
                    </th>
                    <th scope="col" className="cursor-pointer">
                      isRequired{" "}
                    </th>
                    <th scope="col" className="cursor-pointer">
                      Action{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>{version}</tbody>
              </table>
              </div>
              <Pagination
                style={{ textAlign: "right" }}
                pageSize={10}
                total={this.state.versionCount ? this.state.versionCount : 1}
                onChange={this.changePage.bind(this)}
              />

              <CustomModal
                handleCancel={this.handleCancel}
                handleOk={this.handleOk}
                visible={this.state.visible}
                title="Version"
                backBtnText="Back"
                submitBtnText="Submit"
              >
                <Form className="sport-form l_hgt">
                  <Form.Item label="Title">
                    {getFieldDecorator("title", {
                      rules: [
                        { required: true, whitespace: true, message: "Please Enter Vesion" },
                      ],
                    })(<Input placeholder="Enter Title" />)}
                  </Form.Item>
                  <Form.Item label="Description">
                    {getFieldDecorator("description", {
                      rules: [
                        {
                         
                          whitespace: true,
                          message: "Please enter description",
                        },
                      ],
                    })(<TextArea placeholder="Enter Version Description" />)}
                  </Form.Item>
                  <Form.Item label="Version">
                    {getFieldDecorator("version", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter version",
                        },
                      ],
                    })(<Input type="number" step="0.01" placeholder="Enter Version" />)}
                  </Form.Item>
                  <Form.Item label="Platform">
                    {getFieldDecorator("platform", {
                      rules: [
                        {
                          required: true,
                          message: "Please select platform",
                        },
                      ],
                    })(
                      <Radio.Group
                      name="radiogroup"
                      onChange={this.onChangePlatform}
                      value={this.state.isRequiredValue}
                    >
                      {this.state.platformArray.map((x) => {
                        return (
                          <Radio
                            value={x.value}
                            checked={x.value == this.state.isRequiredValue}
                          >
                            {" "}
                            {x.name}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                    )}
                  </Form.Item>
                  <Form.Item label="Allow force update?">
                    {getFieldDecorator("isrequired", {
                      rules: [
                        {
                          required: true,
                          message: "Please select any value",
                        },
                      ],
                    })(
                      <Radio.Group
                    name="radiogroup"
                    onChange={this.onChange}
                    value={this.state.isRequiredValue}
                  >
                    {this.state.radioArray.map((x) => {
                      return (
                        <Radio
                          value={x.value}
                          checked={x.value == this.state.isRequiredValue}
                        >
                          {" "}
                          {x.name}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                    )}
                  </Form.Item>
                 
                </Form>
              </CustomModal>
            </div>
          </Spin>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    versionData: state.admin.versionData,
    versionCount: state.admin.versionCount,
  };
}
const version = Form.create({ name: "version" })(Version);
export default connect(mapStateToProps, {
  getVersion,
  addVersion,
  removeVersion,
})(version);

import React, { useState } from 'react';
import { Layout, Icon, Avatar, Popover } from 'antd';
import { Link } from "react-router-dom";
import toptippers from '../img/logo.svg';
const { Header } = Layout;

function AppHeader(props) {
  const userMenuOptions = (
    <div className='profile-menu'>
      <p onClick={() => handleVisibleChange()}><Link to="/profile"><Icon type="user" /> My Profile</Link></p>
      <p onClick={() => handleVisibleChange()}><Link to="/change-password"><Icon type="lock" /> Change Password</Link></p>
      <p onClick={() => handleVisibleChange()}><Link to="/"><Icon type="logout" /> Logout</Link></p>
    </div>
  );
  const notificationMenuOptions = (
    <div>
      <p>Notifications</p>
    </div>
  );
  const [visible, setVisible] = useState(false);
  function handleVisibleChange() {
    setVisible(!visible)
  }
  return (<Header >

    <Icon
      className="trigger"
      type={props.collapsed ? 'menu-unfold' : 'menu-fold'}
      onClick={props.toggle}
    />
     <div className="mobile-logos">
        {/* <b>Tipping App</b> */}
        <img src={toptippers} alt="toptippers" />
      </div>
    <span style={{ float: 'right' }}>
      {/* <Popover placement="bottomRight" content={notificationMenuOptions} trigger="click">
        <Icon type={'bell'} style={{ fontSize: '18px', padding: '5px' }} />
      </Popover> */}
      <span>
        <Popover placement="bottomRight" visible={visible}
          onVisibleChange={() => handleVisibleChange()} content={userMenuOptions} trigger="click">
          <Avatar src={'http://via.placeholder.com/150x150'}
            className="gx-size-50 gx-pointer cursor-pointer" alt="" />
          <div className='name-role'>
            <span>Merica John
              <svg width="10" className='ml-2' height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.7" d="M5.86603 7.5C5.48113 8.16667 4.51887 8.16667 4.13397 7.5L0.669874 1.5C0.284974 0.833333 0.7661 -8.94676e-07 1.5359 -8.27378e-07L8.4641 -2.21695e-07C9.2339 -1.54397e-07 9.71503 0.833333 9.33013 1.5L5.86603 7.5Z" fill="#073141" />
              </svg>

            </span>
            <small>Admin</small>
          </div>
        </Popover>
      </span>
    </span>
  </Header>);
}

export default AppHeader;

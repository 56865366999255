import React, { Component } from "react";
import { Button, Form, Input, Icon } from "antd";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { forgotPassword } from '../modules/login/actions/loginActions';
import { connect } from 'react-redux';

const FormItem = Form.Item;

class ForgotPassword extends Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                this.props.forgotPassword(values)
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-login-container">
                <Container>
      <Row>
        <Col>
                <div className="gx-login-content">

                    <div className="gx-login-header">
                        <img src={require('../img/logo.svg')} alt="" className='img-fluid' />
                    </div>
                    <hr/>
                    <div className="gx-mb-4 gx-text-center">
                        <h2>Forgot Password</h2>
                    </div>

                    <Form layout="vertical" onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">

                        <FormItem label="Email">
                            {getFieldDecorator('email', {
                                rules: [{
                                    type: 'email', message: 'The input is not valid email',
                                }, {
                                    required: true, message: 'Please enter your email',
                                }],
                            })(
                                <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="Email" />
                            )}
                        </FormItem>
                        
                        <FormItem>
                            <Button type="primary" style={{ width: '100%' }} htmlType="submit">
                                Send
                            </Button>
                        </FormItem>
                        <FormItem className="text-center">
                            <Link className="gx-login-form-forgot" to="/"><Icon type="arrow-left" /> Back to Sign In</Link>
                        </FormItem>
                    </Form>

                </div>
                </Col>
                </Row>
                </Container>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {};
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default connect(mapStateToProps, { forgotPassword })(WrappedForgotPasswordForm);


import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Icon,
  Select,
  Upload,
  Spin,
  DatePicker,
  TimePicker,
} from "antd";
import { connect } from "react-redux";
import {
  addArticle,
  ArticlFileUpload,
  getSport,
  getRound,
  getGame,
  ArticleDetails,
  editArticle,
  getTeam,
} from "../admin/actions/adminActions";
import TextEditor from "../../components/common/TextEditor";
import moment from "moment";
const { Option } = Select;

class EditeArticle extends Component {
  constructor() {
    super();

    this.state = {
      content: "",
      fileList: [],
      htmlFile: [],
      sportData: [],
      roundData: [],
      selectRoundData: [],
      selectGameData: [],
      gameData: [],
      teamData: [],
      sportTeam: [],
      roundId: null,
      sportId: null,
      gameId: null,
      loading: false,
      artilcType: [
        { name: "NRL" },
        { name: "AFL" },
        { name: "Cricket" },
        { name: "Others" },
      ],
    };
    this.handleModelChange = this.handleModelChange.bind(this);
  }

  componentDidMount() {
    let getSportList = {
      count: 1000,
      isActive: true,
    };
    this.props.getSport(getSportList);
    let selection_obj = {
      count: 1000,
      season: "current",
    };
    this.props.getRound(selection_obj);
    this.props.getGame(selection_obj);
    this.getTeam();
    setTimeout(() => {
      this.getArticleDetails();
    }, 600);
  }

  componentWillReceiveProps(nextProps) {
    let data = nextProps.roundData;
    this.setState({
      roundData: data,
      totalRecord: nextProps.totalCount,
    });
    let sportdata = nextProps.sportData;
    this.setState({ sportData: sportdata });
    let gamedata = nextProps.gameData;
    this.setState({ gameData: gamedata });
    let team = nextProps.teamData;
    this.setState({ teamData: team });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  }

  getTeam() {
    let obj1 = {
      count: 1000,
    };
    this.props.getTeam(obj1);
  }
  handleTeamSportSelect(value) {
    if (value === "") {
      var teams = this.state.teamData;
    } else {
      teams = this.state.teamData.filter((ele) => ele.sport._id === value);
    }

    console.log("value", teams);
    this.setState({
      sportTeam: teams,
    });
  }

  handleSportSelect(value) {
    var roundData = this.state.roundData;
    roundData = roundData.filter((round) => round.sport._id === value);
    this.setState({
      sportId: value,
      selectRoundData: roundData,
    });
  }
  handleRoundSelect(value) {
    console.log("value", value);
    let thiss = this;
    let selectGameData = thiss.state.gameData.filter(
      (ele) => ele?.round?._id === value
    );
    console.log("selectGameData", selectGameData);
    thiss.setState({
      roundId: value,
      selectGameData: selectGameData,
    });
  }

  handleModelChange(model) {
    // const editorValue = model.current.editor.getValue();
    console.log("mode;", model);
    let data = model;
    this.setState({
      content: data,
    });
  }
  handleSubmit = (isDroft) => {
    this.props.form.validateFields((err, values) => {
      let gameTime = new Date(values.time);
      let publishDateTime = new Date(values.date);
      publishDateTime.setHours(
        gameTime.getHours(),
        gameTime.getMinutes(),
        "00"
      );
      this.setState({ loading: true });

      if (!err) {
        if (
          this.state.fileList.length === 0 ||
          this.state.htmlFile.length === 0
        ) {
          message.error("Please upload a file");
          return;
        }
        let formData = new FormData();
        formData.append("title", values.title);
        formData.append("content", this.state.content);
        formData.append("addedby", values.addedby);
        formData.append("files", this.state.fileList[0]);
        formData.append("htmlFiles", this.state.htmlFile[0]);
        formData.append("game", values.game);
        formData.append("url", values.url);
        formData.append("isActive", isDroft);
        formData.append("publishDateTime", publishDateTime);
        formData.append("teamId", values.team);
        formData.append("articleType", values.type);
        for (let [values, key] of formData) {
          console.log(values + ":" + key);
        }
        this.props.addArticle(formData).then((data) => {
          this.setState({ loading: false });
          this.navigateToHome();
        });
      }
    });
  };
  handleEdit = (e) => {
    const articleId = this.props.match.params.id;
    this.props.form.validateFields((err, values) => {
      let gameTime = new Date(values.time);
      let publishDateTime = new Date(values.date);
      publishDateTime.setHours(
        gameTime.getHours(),
        gameTime.getMinutes(),
        "00"
      );
      if (!err) {
        let formData = new FormData();
        formData.append("title", values.title);
        formData.append("content", this.state.content);
        formData.append("addedby", values.addedby);
        formData.append("files", this.state.fileList[0]);
        formData.append("htmlFiles", this.state.htmlFile[0]);
        formData.append("game", values.game);
        formData.append("url", values.url);
        formData.append("_id", articleId);
        formData.append("publishDateTime", publishDateTime);
        formData.append("teamId", values.team);
        formData.append("articleType", values.type);
        // for(let [values,key  ] of formData){
        //    console.log(values+":"+key)
        // }

        console.log("this.state.content", this.state.content);
        this.props.editArticle(formData).then((data) => {
          console.log("uploaded", data);
          this.navigateToHome();
        });
      }
    });
  };
  handleUpload = (file) => {
    console.log(file);
    this.setState({ fileList: [file] });
    return false;
  };
  handleUploadHtml = (file) => {
    console.log(file);
    this.setState({ htmlFile: [file] });
    return false;
  };
  getArticleDetails() {
    const articleId = this.props.match.params.id;
    try {
      if (articleId !== undefined) {
        this.setState({ loading: true });
        let obj = {
          _id: articleId,
        };
        this.props.ArticleDetails(obj).then((data) => {
          this.handleTeamSportSelect("");
          if (data.gameId != null) {
            this.handleSportSelect(data.gameId.sport);
            this.handleRoundSelect(data.gameId.round);
          }

          this.props.form.setFieldsValue({
            title: data.title,

            addedby: data.addedby,
            team: data.teamId,
            url: data.redirectUrl,
            type: data.articleType,
            time: moment(new Date(data.publishDateTime), "HH:mm"),
            date: moment(new Date(data.publishDateTime), "YYYY/MM/DD"),
            sport: data?.gameId?.sport || "",
            round: data?.gameId?.round || "",
            game: data?.gameId?._id || "",
          });

          this.setState({
            content: data.content,
            loading: false,
          });
        });
      }
    } catch (err) {
      console.log("error", err);
    }
  }
  navigateToHome = () => {
    this.props.history.push(`/ladder`);
  };
  render() {
    let state = this.state;
    const articleId = this.props.match.params.id;

    const { getFieldDecorator } = this.props.form;
    const sport = state.sportData.map((x, index) => (
      <Option value={x._id}>{x.sportname}</Option>
    ));
    const round = this.state.selectRoundData.map((x, index) => (
      <Option value={x._id}>{x.roundname}</Option>
    ));
    const game = this.state.selectGameData.map((x, index) => (
      <Option value={x._id}>
        {x.homeTeam.teamname + " vs " + x.awayTeam.teamname}
      </Option>
    ));
    const team = this.state.sportTeam.map((x, index) => (
      <Option value={x._id}>{x.teamname}</Option>
    ));
    const articleType = this.state.artilcType.map((x, index) => (
      <Option value={x.name}>{x.name}</Option>
    ));
    return (
      <div>
        <Spin spinning={this.state.loading} delay={500}>
          <div>
            <Form className="login-form">
              <div className="form-inner">
                <Form.Item label="Title">
                  {getFieldDecorator("title", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter title",
                      },
                    ],
                  })(<Input placeholder="Title" />)}
                </Form.Item>
                <Form.Item label="writter">
                  {getFieldDecorator("addedby", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter writter name",
                      },
                    ],
                  })(<Input placeholder="writter name" />)}
                </Form.Item>
                <Form.Item label="Redairect url">
                  {getFieldDecorator("url", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter redairect   url",
                      },
                    ],
                  })(<Input placeholder="enter  redairect" />)}
                </Form.Item>
                <Form.Item label="Select Article Type">
                  {getFieldDecorator("type", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please select type",
                      },
                    ],
                  })(<Select placeholder=" Select Type">{articleType}</Select>)}
                </Form.Item>
              </div>

              <div className="form-inner four_itm_div">
                <Form.Item label="Date">
                  {getFieldDecorator("date", {
                    rules: [{ required: true, message: "Please enter date" }],
                  })(<DatePicker placeholder="Date" />)}
                </Form.Item>
                <Form.Item label="Time">
                  {getFieldDecorator("time", {
                    rules: [{ required: true, message: "Please enter time" }],
                  })(<TimePicker placeholder="Time" format={"HH:mm"} />)}
                </Form.Item>
                <Form.Item label="Article Image/Video">
                  {getFieldDecorator(
                    "file",
                    {}
                  )(
                    <Upload
                      name="file"
                      beforeUpload={this.handleUpload}
                      fileList={this.state.fileList}
                    >
                      <Button>
                        <Icon type="upload" /> Click to Upload
                      </Button>
                    </Upload>
                  )}
                </Form.Item>
                <Form.Item label="TopSport HTML Banner">
                  {getFieldDecorator(
                    "html",
                    {}
                  )(
                    <Upload
                      name="html"
                      beforeUpload={this.handleUploadHtml}
                      fileList={this.state.htmlFile}
                    >
                      <Button>
                        <Icon type="upload" /> Click to Upload
                      </Button>
                    </Upload>
                  )}
                </Form.Item>
              </div>

              <div className="form-inner search_team">
                <Form.Item label="Select Sport for team ">
                  {getFieldDecorator("Team sport ", {
                    rules: [
                      {
                        whitespace: true,
                        message: "Please select team",
                      },
                    ],
                  })(
                    <Select
                      onChange={this.handleTeamSportSelect.bind(this)}
                      placeholder="Search to Select"
                    >
                      {sport}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Select  team">
                  {getFieldDecorator("team", {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Please select team",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option.props.children || "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {team}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Select Sports">
                  {getFieldDecorator("sport", {
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: "Please select sports",
                      },
                    ],
                  })(
                    <Select
                      onChange={this.handleSportSelect.bind(this)}
                      placeholder=" Select Sport "
                    >
                      {sport}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Select Round">
                  {getFieldDecorator("round", {
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: "Please select round",
                      },
                    ],
                  })(
                    <Select
                      onChange={this.handleRoundSelect.bind(this)}
                      placeholder=" Select Round "
                    >
                      {round}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Select Game">
                  {getFieldDecorator("game", {
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: "Please select game",
                      },
                    ],
                  })(<Select placeholder=" Select Game ">{game}</Select>)}
                </Form.Item>
              </div>
              <TextEditor
                handleModelChange={this.handleModelChange}
                ArticlFileUpload={this.props.ArticlFileUpload}
                value={this.state.content}
              />
            </Form>
          </div>
          {articleId ? (
            <div>
              <Button type="primary" onClick={() => this.handleEdit()}>
                <Icon type="primary" /> Save Edit
              </Button>
            </div>
          ) : (
            <div className="form-inner">
              <Button type="primary" onClick={() => this.handleSubmit(false)}>
                <Icon type="primary" /> Draft
              </Button>
              <Button type="primary" onClick={() => this.handleSubmit(true)}>
                <Icon type="primary" /> Save
              </Button>
            </div>
          )}
        </Spin>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    state,
    sportData: state.admin.sportData,
    gameData: state.admin.gameData,
    roundData: state.admin.roundData,
    teamData: state.admin.teamData,
  };
}
const article = Form.create({ name: "article" })(EditeArticle);
export default connect(mapStateToProps, {
  addArticle,
  ArticlFileUpload,
  getSport,
  getRound,
  getGame,
  ArticleDetails,
  editArticle,
  getTeam,
})(article);

import React, { Component } from 'react'
import JoditEditor from 'jodit-react';
export default class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: ""
        }
    }
  
    onBlur = (newContent) => {
        this.props.handleModelChange(newContent)
    };
     FILE_UPLOADER = {
        uploader: {
            enableDragAndDropFileToEditor: true,
            url: `http://54.201.160.69:9194/api/article/uploadfile`,
            insertImageAsBase64URI: false,
            imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
            filesVariableName: function (t) {
                return "files";
            },
            withCredentials: false,
            isSuccess: function (resp) {
                return resp;
            },

            process: function (resp) {
                console.log("response", resp);
                return {
                    files: resp.data,
                    baseurl: `http://localhost:3019/`,
                    error: '',
                    msg: ''
                };
            },

            defaultHandlerSuccess: function (data) {
                console.log("uploaded", data);
                this.selection.insertImage(data.files);
                var i, field = 'files';
                if (data[field] && data[field].length) {
                    for (i = 0; i < data[field].length; i += 1) {
                        if (['jpg', 'png', 'jpeg', 'gif'].includes(data[field][i].split('.').pop()))
                            this.selection.insertImage(data.baseurl + data[field][i]);
                        // else toast.warn("File type not allowed");
                    }
                }
            },
        },
        readonly: false,
        height: 400,
        minHeight: 200,
      
    };

    render() {
        return (
            <div>
                <div className="App" style={{ maxWidth: "auto", margin: "0 auto" }}>

                    <JoditEditor
                        value={this.props.value?this.props.value:this.state.content}
                        config={this.FILE_UPLOADER}
                        tabIndex={1}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                    />

                </div>
            </div>
        )
    }
}

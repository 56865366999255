import { Button, DatePicker, PageHeader, Select, Spin, Table, } from 'antd'
import React, { Component } from 'react'
import { getSport, getCompbySport, GetCsvData } from ".././admin/actions/adminActions";
import { connect } from 'react-redux';
import toastr from 'toastr';
import { CSVLink } from "react-csv";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

class Banter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sportData: [],
            loading: true,
            compData: [],
            selectedComp: null,
            startDate: null,
            endDate: null,
            buttonLoding: false,
            csvData: [],
            competitionName: null

        }


    }
    componentDidMount() {
        this.setState({ loading: true });
        let obj1 = {
            count: 1000,
            isActive: true
        };
        this.props.getSport(obj1);

    }
    componentWillReceiveProps(nextProps) {
        let sportdata = nextProps.sportData;
        this.setState({ sportData: sportdata, loading: false });
    }
    handelSport(value) {
        this.setState({ loading: true })
        let obj = {
            sportId: value,
            count:1000
        }
        this.props.getCompbySport(obj).then((data) => {
            // console.log("value", data)
            const result = data.filter(ele=>ele.isPublic===false&&ele.isBanter===true)
            // console.log("result",result)
            this.setState({ loading: false, compData: result })
        })
    }

    handelDatePicker(value) {
        this.setState({ startDate: value[0]._d, endDate: value[1]._d })
    }

    handelok() {
        const { selectedComp, startDate, endDate } = this.state
        if (selectedComp === null || startDate === null, endDate === null) {
            return toastr.warning('plase select all field')
        }
        this.setState({ buttonLoding: true })

        let payload = {
            compId: selectedComp,
            startDate: startDate,
            endDate: endDate


        }
        this.props.GetCsvData(payload).then((data) => {
            if(data.length<1){
                toastr.info("There have no  any messages in this banter")
                this.setState({ buttonLoding: false,})
            }else{
                data.data.map((ele) => {
                    const momentObject = moment(ele.createdAt, 'YYYY-MM-DD HH:mm:ss');
                    const formattedDateTime = momentObject.format('MMMM DD, YYYY h:mm A');
                    return ele.createdAt = formattedDateTime
    
                })
                this.setState({ buttonLoding: false, csvData:data?.data, competitionName:data.competitionName })
            }
        })
    }



    render() {
        const sport = this.state.sportData.map((x, index) => (
            <Option value={x._id}>{x.sportname}</Option>
        ));
        const comp = this.state.compData.map((x, index) => (
            <Option value={x._id}>{x.competitionname}</Option>
        ));
        let headers = [
            { label: "Email", key: "email" },
            // { label: "User Name", key: "lastname" },
            { label: "Name", key: "name" },
            { label: "Message", key: "message" },
            { label: "media", key: "media" },
            { label: "date/time", key: "createdAt" }
        ];
        return (
            <>
                <div>
                    <Spin spinning={this.state.loading} delay={500}>
                        <PageHeader
                            title="Banter"
                            subTitle=""

                        />

                        <div className='bunter'>
                            <div className='bunter-filter'>
                                <Select defaultValue="" placeholder="sports " style={{ width: 150 }}
                                    onChange={this.handelSport.bind(this)}
                                >
                                    <   Option value="" disabled>select sport </Option>
                                    {
                                        sport
                                    }
                                </Select>

                                <Select defaultValue="" placeholder="competitions" style={{ width: 150 }}
                                    onChange={(e) => {
                                        this.setState({ selectedComp: e })
                                    }}
                                >
                                    <   Option value="" disabled>select competition  </Option>
                                    {
                                        comp
                                    }
                                </Select>

                                <RangePicker
                                    onChange={this.handelDatePicker.bind(this)
                                    }
                                    placeholder="Date"
                                />
                                <Button type="primary" loading={this.state.buttonLoding} onClick={() => { this.handelok() }} >
                                    Filter
                                </Button>
                                {this.state.csvData?.length >0 ?
                                    <CSVLink filename={`${this.state.competitionName}.csv`}
                                        className="btn btn-primary"
                                        target="_blank"
                                        data={this.state.csvData} headers={headers}

                                    >
                                        Download {this.state.competitionName}
                                    </CSVLink>
                                    : null}
                            </div>

                        </div>


                        {/* {this.state.csvData?.length < 1 ?
                                 <div className='bunter'>
                                    <div className='bunter-filter'>

                                          <p4>competitionName:<span>${this.state.competitionName}</span></p4>


                                        
                                    </div>
                                     </div>
                                    : null} */}










                    </Spin>

                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        sportData: state.admin.sportData,
    };
}
export default connect(mapStateToProps, {
    getSport,
    getCompbySport,
    GetCsvData
})(Banter);

import React, { Component } from "react";
import {
  Statistic,
  Card,
  DatePicker,
  // Descriptions,
  // Button,
  // Modal,
  Select,
  Form,
  Icon,
  Button,
  // Switch,
  // Input,
  // Pagination,
  // Spin,
} from "antd";
// import * as constant from "../../../actions/constant";
// import CustomModal from "../../../components/common/CustomModal";
// import tradeDown from "../../../img/trending-down.png";
import { CalendarOutline } from '@ant-design/icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import tradeUp from "../../../img/trending-up.png";
import { connect } from "react-redux";
import {
  getUsers,
  getSport,
  getCompetitions,
  getSetting,
  addSetting,
  clearSession,
  getSeason,
  addSeason,
  removeSeason,
} from "../../admin/actions/adminActions";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
import moment from "moment";
const data = [
  {
    name: "10/12/2019",
    AFL: 55,
    NRL: 45,
  },
  {
    name: "11/12/2019",
    AFL: 65,
    NRL: 30,
  },
  {
    name: "12/12/2019",
    AFL: 30,
    NRL: 45,
  },
];

const { RangePicker } = DatePicker;
const { Option } = Select;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCount: 0,
      sportCount: 0,
      competitionCount: 0,
      currentPage: 0,
      sortValue: "",
      sortOrder: "",
      visible: false,
      modelVisible: false,
      isTesting: false,
      currentDate: "",
      settingData: {},
      newSeason: false,
      sportData: [],
      seasonData: [],
      loading: true,
      viewModalVisible: false,
      viewSeasonData: "",
      seasonCount: 0,
    };
  }

  componentDidMount() {
    let obj = {
      page: this.state.currentPage,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
    };
    this.props.getSport(obj);
    this.props.getUsers(obj);
    this.props.getCompetitions(obj);
    this.props.getSetting({ type: "testing" });
    // this.props.getSeason(obj);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      userCount: nextProps.userCount,
      sportCount: nextProps.sportCount,
      competitionCount: nextProps.competitionCount,
      settingData: nextProps.settingData,
      SeasonCount: nextProps.seasonCount
    });
    let sesData = nextProps.seasonData;
    this.setState({ seasonData: sesData, loading: false });
  }



  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let obj = {
          settingId: this.state.settingData._id,
          currentDate: values.date,
          isTesting: this.state.isTesting,
        };
        this.props.addSetting(obj);
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
      isTesting: this.state.settingData.isTesting,
      currentDate: this.state.settingData.currentDate,
    });
  };

  onTestingChange = (checked) => {
    console.log(`switch to ${checked}`);
    this.setState({
      isTesting: checked,
    });
  };


  disabledDate = (current) => {
    return current < moment(this.state.currentDate, "YYYY-MM-DD");
  };



  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <>
        <h2 className="page-heading">Dashboard</h2>
        <Row>
          <Col lg={4}>
            <Row>
              <Col className="gutter-row" md={12}>
                <div className="gutter-box">
                  {/* <Card hoverable className="mb-4">
                  <Statistic
                    title="Total Users"
                    value={this.state.userCount}
                    precision={0}
                    valueStyle={{ color: "#F9897F" }}
                    prefix={<img alt="trade" src={tradeUp} />}
                  // suffix="%"
                  />
                </Card> */}
                  <Card hoverable className="mb-3">
                    <div className="d-flex">
                      <div className="left-icon skyblue">
                        <span>
                          <img src={require('../../../img/total-user.png')} alt="" className='img-fluid' />
                        </span>
                      </div>
                      <div className="count-no">
                        {this.state.userCount}
                        <small>
                          <svg width="23" height="13" className="mr-2" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.1793 0.000139742H13.9684C13.5151 0.000139742 13.1473 0.363575 13.1473 0.811808C13.1473 1.26032 13.5151 1.62376 13.9684 1.62376H20.197L12.3261 9.40642L7.98007 5.10939C7.82613 4.9569 7.61747 4.87154 7.39974 4.87154C7.18201 4.87154 6.97308 4.9569 6.81914 5.10939L0.250748 11.6041C0.0924007 11.7555 0.00195268 11.9632 3.14014e-05 12.181C-0.00189298 12.3987 0.0847014 12.6083 0.240578 12.7621C0.396174 12.9162 0.608135 13.0019 0.828336 13C1.04854 12.9981 1.25857 12.9086 1.41169 12.7521L7.39976 6.83114L11.7455 11.1282V11.1284C11.8995 11.2807 12.1084 11.3663 12.3261 11.3663C12.5438 11.3663 12.7525 11.2807 12.9064 11.1284L21.358 2.77168V8.93045C21.358 9.37869 21.7255 9.7424 22.1791 9.7424C22.6325 9.7424 23 9.37869 23 8.93045V0.811677C23.0003 0.596387 22.9137 0.389803 22.7597 0.23758C22.6058 0.0853573 22.3969 -0.000270748 22.1791 6.43153e-07L22.1793 0.000139742Z" fill="#299D00" />
                          </svg>
                          20% From last year</small>
                      </div>
                    </div>
                    <div className="bottom-text">
                      Total Users
                    </div>
                  </Card>

                </div>
              </Col>
              <Col className="gutter-row" md={12}>
                <div className="gutter-box">
                  {/* <Card hoverable className="mb-4">
                    <Statistic
                      title="Total Sports"
                      value={this.state.sportCount}
                      precision={0}
                      valueStyle={{ color: "#8884d8" }}
                      prefix={<img alt="trade" src={tradeUp} />}
                    // suffix="%"
                    />
                  </Card> */}
                  <Card hoverable className="mb-3">
                    <div className="d-flex">
                      <div className="left-icon red">
                        <span>
                          <img src={require('../../../img/total-sports.svg')} alt="" className='img-fluid' />
                        </span>
                      </div>
                      <div className="count-no">
                        {this.state.sportCount}
                        <small>
                          <svg width="23" height="13" className="mr-2" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.1793 0.000139742H13.9684C13.5151 0.000139742 13.1473 0.363575 13.1473 0.811808C13.1473 1.26032 13.5151 1.62376 13.9684 1.62376H20.197L12.3261 9.40642L7.98007 5.10939C7.82613 4.9569 7.61747 4.87154 7.39974 4.87154C7.18201 4.87154 6.97308 4.9569 6.81914 5.10939L0.250748 11.6041C0.0924007 11.7555 0.00195268 11.9632 3.14014e-05 12.181C-0.00189298 12.3987 0.0847014 12.6083 0.240578 12.7621C0.396174 12.9162 0.608135 13.0019 0.828336 13C1.04854 12.9981 1.25857 12.9086 1.41169 12.7521L7.39976 6.83114L11.7455 11.1282V11.1284C11.8995 11.2807 12.1084 11.3663 12.3261 11.3663C12.5438 11.3663 12.7525 11.2807 12.9064 11.1284L21.358 2.77168V8.93045C21.358 9.37869 21.7255 9.7424 22.1791 9.7424C22.6325 9.7424 23 9.37869 23 8.93045V0.811677C23.0003 0.596387 22.9137 0.389803 22.7597 0.23758C22.6058 0.0853573 22.3969 -0.000270748 22.1791 6.43153e-07L22.1793 0.000139742Z" fill="#299D00" />
                          </svg>
                          20% From last year</small>
                      </div>
                    </div>
                    <div className="bottom-text">
                      Total Sports
                    </div>
                  </Card>
                </div>
              </Col>
              <Col className="gutter-row" md={12}>
                <div className="gutter-box">
                  {/* <Card hoverable className="mb-4">
                    <Statistic
                      title="Total Competitions"
                      value={this.state.competitionCount}
                      precision={0}
                      valueStyle={{ color: "#82ca9d" }}
                      prefix={<img alt="trade" src={tradeUp} />}
                    // suffix="%"
                    />
                  </Card> */}
                  <Card hoverable className="mb-3">
                    <div className="d-flex">
                      <div className="left-icon orange">
                        <span>
                          <img src={require('../../../img/Union.svg')} alt="" className='img-fluid' />
                        </span>
                      </div>
                      <div className="count-no">
                        {this.state.competitionCount}
                        <small>
                          <svg width="23" height="13" className="mr-2" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.1793 0.000139742H13.9684C13.5151 0.000139742 13.1473 0.363575 13.1473 0.811808C13.1473 1.26032 13.5151 1.62376 13.9684 1.62376H20.197L12.3261 9.40642L7.98007 5.10939C7.82613 4.9569 7.61747 4.87154 7.39974 4.87154C7.18201 4.87154 6.97308 4.9569 6.81914 5.10939L0.250748 11.6041C0.0924007 11.7555 0.00195268 11.9632 3.14014e-05 12.181C-0.00189298 12.3987 0.0847014 12.6083 0.240578 12.7621C0.396174 12.9162 0.608135 13.0019 0.828336 13C1.04854 12.9981 1.25857 12.9086 1.41169 12.7521L7.39976 6.83114L11.7455 11.1282V11.1284C11.8995 11.2807 12.1084 11.3663 12.3261 11.3663C12.5438 11.3663 12.7525 11.2807 12.9064 11.1284L21.358 2.77168V8.93045C21.358 9.37869 21.7255 9.7424 22.1791 9.7424C22.6325 9.7424 23 9.37869 23 8.93045V0.811677C23.0003 0.596387 22.9137 0.389803 22.7597 0.23758C22.6058 0.0853573 22.3969 -0.000270748 22.1791 6.43153e-07L22.1793 0.000139742Z" fill="#299D00" />
                          </svg>
                          20% From last year</small>
                      </div>
                    </div>
                    <div className="bottom-text">
                      Total Competitions
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={8}>
            <Card hoverable className="mb-4 info-chart">
              <Row>
                <Col sm={4} className="mb-3">
                  <h2 className="green-clr">Analysis</h2>
                  <Form layout="inline" className="d-flex">
                    <label> <Icon type="calendar" /></label>
                    <Select className="drop-select"
                      placeholder="This Week"
                    >
                      <Option value="other">This Day</Option>
                      <Option value="male">This Month</Option>
                      <Option value="female">This Yrea</Option>

                    </Select>
                  </Form>
                </Col>
                <Col sm={4} className="mb-3">
                  <h2 className="purple-clr">24,895</h2>
                  <h4>
                    Total Sports
                  </h4>
                  <small>
                    <svg width="23" height="13" className="mr-2" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.1793 0.000139742H13.9684C13.5151 0.000139742 13.1473 0.363575 13.1473 0.811808C13.1473 1.26032 13.5151 1.62376 13.9684 1.62376H20.197L12.3261 9.40642L7.98007 5.10939C7.82613 4.9569 7.61747 4.87154 7.39974 4.87154C7.18201 4.87154 6.97308 4.9569 6.81914 5.10939L0.250748 11.6041C0.0924007 11.7555 0.00195268 11.9632 3.14014e-05 12.181C-0.00189298 12.3987 0.0847014 12.6083 0.240578 12.7621C0.396174 12.9162 0.608135 13.0019 0.828336 13C1.04854 12.9981 1.25857 12.9086 1.41169 12.7521L7.39976 6.83114L11.7455 11.1282V11.1284C11.8995 11.2807 12.1084 11.3663 12.3261 11.3663C12.5438 11.3663 12.7525 11.2807 12.9064 11.1284L21.358 2.77168V8.93045C21.358 9.37869 21.7255 9.7424 22.1791 9.7424C22.6325 9.7424 23 9.37869 23 8.93045V0.811677C23.0003 0.596387 22.9137 0.389803 22.7597 0.23758C22.6058 0.0853573 22.3969 -0.000270748 22.1791 6.43153e-07L22.1793 0.000139742Z" fill="#299D00" />
                    </svg>
                    20% From last year</small>
                </Col>
                <Col sm={4} className="mb-3">
                  <h2 className="blue-clr">24,895</h2>
                  <h4>
                    Total Competitions
                  </h4>
                  <small>
                    <svg width="23" height="13" className="mr-2" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.820745 12.9999H9.03158C9.48491 12.9999 9.85274 12.6364 9.85274 12.1882C9.85274 11.7397 9.48491 11.3762 9.03158 11.3762H2.80297L10.6739 3.59358L15.0199 7.89061C15.1739 8.0431 15.3825 8.12846 15.6003 8.12846C15.818 8.12846 16.0269 8.0431 16.1809 7.89061L22.7493 1.39587C22.9076 1.24446 22.998 1.03678 23 0.819048C23.0019 0.601315 22.9153 0.391739 22.7594 0.237881C22.6038 0.0837584 22.3919 -0.00186825 22.1717 3.14713e-05C21.9515 0.00193405 21.7414 0.0913649 21.5883 0.247936L15.6002 6.16886L11.2545 1.87183V1.87156C11.1005 1.71934 10.8916 1.63371 10.6739 1.63371C10.4562 1.63371 10.2475 1.71934 10.0936 1.87156L1.64203 10.2283V4.06955C1.64203 3.62131 1.27447 3.2576 0.820871 3.2576C0.367548 3.2576 0 3.62131 0 4.06955V12.1883C-0.000276566 12.4036 0.0863266 12.6102 0.240274 12.7624C0.394224 12.9146 0.603149 13.0003 0.820881 13L0.820745 12.9999Z" fill="#FF2416" />
                    </svg>

                    20% From last year</small>

                </Col>
                <Col md={12}>
                  <img src={require('../../../img/dummy-chart.png')} alt="" className='img-fluid mt-5' />
                </Col>
              </Row>
            </Card>
          </Col>

        </Row>
        <Row>
          <Col sm={12}>
            <Card className="mb-3">
              <h4 className="mb-4">New User Added</h4>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th >
                        Name
                      </th>
                      <th scope="col">Email</th>
                      <th scope="col">Country</th>
                      <th scope="col">TopSport</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Nirav</td>
                      <td>niravrv109@gmail.com</td>
                      <td>Algeria</td>
                      <td>No</td>
                      <td>Sucess</td>
                      <td className="action-icons">
                        <Button type="link" className="btn-sm mr-2">
                          <Icon type="eye" theme="filled" />
                        </Button>
                        <Button type="link" className=" btn-sm mr-2" >
                          <Icon type="delete" theme="filled" />
                        </Button>
                        <Button type="link" className="btn-sm">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Nirav</td>
                      <td>niravrv109@gmail.com</td>
                      <td>Algeria</td>
                      <td>No</td>
                      <td>Sucess</td>
                      <td className="action-icons">
                        <Button type="link" className="btn-sm mr-2">
                          <Icon type="eye" theme="filled" />
                        </Button>
                        <Button type="link" className=" btn-sm mr-2" >
                          <Icon type="delete" theme="filled" />
                        </Button>
                        <Button type="link" className="btn-sm">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Nirav</td>
                      <td>niravrv109@gmail.com</td>
                      <td>Algeria</td>
                      <td>No</td>
                      <td>Sucess</td>
                      <td className="action-icons">
                        <Button type="link" className="btn-sm mr-2">
                          <Icon type="eye" theme="filled" />
                        </Button>
                        <Button type="link" className=" btn-sm mr-2" >
                          <Icon type="delete" theme="filled" />
                        </Button>
                        <Button type="link" className="btn-sm">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Nirav</td>
                      <td>niravrv109@gmail.com</td>
                      <td>Algeria</td>
                      <td>No</td>
                      <td>Sucess</td>
                      <td className="action-icons">
                        <Button type="link" className="btn-sm mr-2">
                          <Icon type="eye" theme="filled" />
                        </Button>
                        <Button type="link" className=" btn-sm mr-2" >
                          <Icon type="delete" theme="filled" />
                        </Button>
                        <Button type="link" className="btn-sm">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
        </Row>

        {/* <Col className="gutter-row" span={6}>
              <div className="gutter-box">
                <Card hoverable className="mb-4">
                  <Statistic
                    title="Total Season"
                    value={this.state.SeasonCount}
                    precision={0}
                    valueStyle={{ color: "#82ca9d" }}
                    prefix={<img alt="trade" src={tradeUp} />}
                  // suffix="%"
                  />
                </Card>
              </div>
            </Col> */}
        <Row gutter={16}>
          {/* <Col className="gutter-row" span={8}>
              <div className="gutter-box">
                <Card hoverable className="mb-4" >
                  <Descriptions title={"Testing (isActive: " + this.state.settingData.isTesting + ")"} layout="vertical">
                    <Descriptions.Item label="Current date">{new Date(this.state.settingData.currentDate).toDateString()}</Descriptions.Item>
                  </Descriptions>
                  <Button type="primary" onClick={this.showModal.bind(this)}>Update</Button>

                  <CustomModal
                    handleCancel={this.handleCancel}
                    handleOk={this.handleOk}
                    visible={this.state.visible}
                    title="Update Testing"
                    backBtnText="Cancel"
                    submitBtnText="Submit">
                    <Form {...layout}>
                      <Form.Item label="Date">
                        {getFieldDecorator('date', {
                          initialValue: moment(new Date(this.state.settingData.currentDate), 'YYYY/MM/DD'),
                          rules: [{ required: true, message: 'Please enter date' }],
                        })(
                          <DatePicker disabledDate={this.disabledDate} placeholder="Date" />
                        )}
                      </Form.Item>
                      <Form.Item label="Testing Mode">

                        <Switch checked={this.state.isTesting} onChange={this.onTestingChange} />

                      </Form.Item>
                    </Form>
                  </CustomModal>

                </Card>
              </div>
            </Col> */}
          {/* <Col className="gutter-row" span={8}>
              <div className="gutter-box">
                <Card hoverable className="mb-4">
                  <Descriptions layout="vertical">
                    <Descriptions.Item label="WARNING">
                      Clicking this button should only be done when you want to
                      create a new season. The season data will be lost
                    </Descriptions.Item>
                  </Descriptions>
                  <Button
                    type="primary"
                    onClick={this.showSessionConfirm.bind(this)}
                  >
                    Start new season
                  </Button>
                </Card>
              </div>
            </Col> */}
        </Row>

        {/* <CustomModal
            handleCancel={this.onCancel}
            handleOk={this.onDone}
            visible={this.state.modelVisible}
            title="New Season"
            backBtnText="Back"
            submitBtnText="Submit"
          >
            <Form className="sport-form l_hgt0">
              <Form.Item label="Season Name">
                {getFieldDecorator("seasonname", {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Please enter Season Name",
                    },
                  ],
                })(<Input placeholder="Season Name" />)}
              </Form.Item>
              <Form.Item label="Select start date and end date">
                {getFieldDecorator("date", {
                  rules: [
                    { required: true, message: "Please enter date range" },
                  ],
                })(
                  <RangePicker
                    disabled={this.state.isRoundUpdate}
                    placeholder="Date"
                  />
                )}
              </Form.Item>
            </Form>
          </CustomModal> */}

        {/*
    <Row>
    <Col lg={24} md={24} xs={24}>
      <Card extra={<MonthPicker defaultValue={moment(new Date(), 'YYYY-MM')} allowClear={false} placeholder="Select month"/>}>
        
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <BarChart
              width={800}
              height={400}
              data={data}
              margin={{
                top: 10, right: 30, left: 0, bottom: 0,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="_id" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="AFL" fill="#F9897F" />
              <Bar dataKey="NRL"fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </Col>
  </Row>
   */}


      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    sportCount: state.admin.sportCount,
    userCount: state.admin.userCount,
    competitionCount: state.admin.competitionCount,
    settingData: state.admin.settingData,
    seasonData: state.admin.seasonData,
    seasonCount: state.admin.seasonCount
  };
}
const Setting = Form.create({ name: "setting" })(Dashboard);
export default connect(mapStateToProps, {
  getSport,
  getUsers,
  getCompetitions,
  getSetting,
  addSetting,
  clearSession,
  getSeason,
  addSeason,
  removeSeason,
})(Setting);

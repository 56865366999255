import React from "react";
import {
    Pagination,
    Button,
    Modal,
    Descriptions,
    PageHeader,
    Form,
    Input,
    Spin,
    Icon,
    Switch,
} from "antd";
import * as constant from "../../actions/constant";
import {
    addArticle,
    ArticleList,
    ActivleAndDeleteArticle
} from "../admin/actions/adminActions";
// import { addRound } from "../admin/actions/adminActions";
import { connect } from "react-redux";
import moment from "moment";
import toastr from "toastr";



const { confirm } = Modal;


class Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            totalRecord: 0,
            limit: 0,
            visible: false,
            sortValue: "",
            sortOrder: "",
            loading: true,
            ArtilceList: [],
            ispopupOpen: false,
            file: null
        };
    }

    handelSearchandGetArticle(payload) {
        this.props.ArticleList(payload).then((data) => {
            this.setState({
                ArtilceList: data?.docs,
                totalRecord: data?.totalDocs,
                limit: data.limit,
                loading: false,
                currentPage: data?.page
            })
            console.log("article date ", data)


        })
    }
    componentDidMount() {
        this.handelSearchandGetArticle()
    }

    handelSearch(text) {
        console.log("text", text)
        let search = {
            search: text
        }
        this.handelSearchandGetArticle(search)
    }
    changePage(page, pageSize) {
        this.setState({
            currentPage: page
        });
        let obj = {
            page: page,
            sortValue: this.state.sortValue,
            sortOrder: this.state.sortOrder,

        };
        console.log("page", page)
        this.handelSearchandGetArticle(obj)

    }
    sortData(sortVal) {
        this.setState({
            sortOrder: this.state.sortOrder === -1 ? 1 : -1,
        });
        let obj = {
            page: this.state.currentPage,
            sortValue: sortVal,
            sortOrder: this.state.sortOrder === -1 ? 1 : -1,
        };
        this.props.getRound(obj);
    }


    handleActiveAndDeleteArticle = (data, key) => {

        let obj = {
            [key]: !data[key],
            _id: data._id
        }
        console.log("obj", obj, data)
        this.props.ActivleAndDeleteArticle(obj).then(() => {
            let obj = {
                page: this.state.currentPage
            }
            this.handelSearchandGetArticle(obj)

        })
    }
    showConfirm(Article) {
        let self = this;
        confirm({
            title: constant.DELETE_RECORD,
            content: '',
            onOk() {
                self.setState({ currentPage: 0 });
                self.handleActiveAndDeleteArticle(Article, "isDeleted")
            },
            onCancel() { },
        });
    }
    navigateToHome = (id) => {
        if (id) {
            this.props.history.push(`/editearticle/${id}`);
        } else {

            this.props.history.push(`/addearticle`);
        }
    }
    render() {
        let state = this.state;
        const round = state.ArtilceList.map((x, index) => (
            <tr key={index}>
                <td>{x?.title}</td>
                {/* <td>{x?.description}</td> */}
                <td><Switch onChange={this.handleActiveAndDeleteArticle.bind(this, x, "isActive")} checked={x.isActive} /></td>
                <td className='action-icons'>
                    <Button
                        className=" btn-sm mr-2" type="link"
                         onClick={()=>this.navigateToHome(x._id)}
                    >
                        <i className="fa fa-pencil"></i>
                    </Button>
                    <Button type='link' onClick={this.showConfirm.bind(this, x)} className=" btn-sm mr-2" ><Icon type="delete" theme="filled" /></Button>
                </td>
            </tr>
        ));

        return (
            <>
                <div className="box box-default mb-4">
                    <PageHeader
                        title="Article"
                        subTitle=""
                        extra={[
                            <Input placeholder="Search" className="mobile-bottom-space" onChange={(e) => this.handelSearch(e.target.value)} style={{ width: 150 }}>
                            </Input>,
                            <Button key="1" type="outline" onClick={() => this.navigateToHome()} ><Icon type="plus" className="mr-1" /> Add Article</Button>
                        ]}
                    />
                    <Spin spinning={this.state.loading} delay={500}>
                        <div className="box-body">
                            <div className='table-responsive'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                onClick={this.sortData.bind(this, "roundno")}
                                                className="cursor-pointer"
                                            >
                                                Title{" "}
                                                {this.state.sortOrder === 1 ? (
                                                    <i
                                                        className="fa fa-sort-amount-asc"
                                                        aria-hidden="true"
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-sort-amount-desc"
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </th>

                                            {/* <th
                                                scope="col"
                                                onClick={this.sortData.bind(this, "roundname")}
                                                className="cursor-pointer"
                                            >
                                                Description{" "}
                                                {this.state.sortOrder === 1 ? (
                                                    <i
                                                        className="fa fa-sort-amount-asc"
                                                        aria-hidden="true"
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fa fa-sort-amount-desc"
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </th> */}
                                            <th
                                                scope="col"
                                                onClick={this.sortData.bind(this, "roundtype")}
                                                className="cursor-pointer"
                                            >
                                                Status {" "}
                                            </th>
                                            <th
                                                scope="col"
                                                onClick={this.sortData.bind(this, "roundtype")}
                                                className="cursor-pointer"
                                            >
                                                Action {" "}

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {round}
                                    </tbody>
                                </table>

                            </div>
                            <Pagination
                                style={{ textAlign: "right" }}
                                pageSize={this.state.limit}
                                current={this.state.currentPage}
                                defaultCurrent={this.state.currentPage}
                                total={this.state.totalRecord ? this.state.totalRecord : 1}
                                onChange={this.changePage.bind(this)}
                            />
                        </div>
                    </Spin>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {

    return {
        state

    };
}
const article = Form.create({ name: "article" })(Article);
export default connect(mapStateToProps, {
    ArticleList,
    addArticle,
    ActivleAndDeleteArticle
})(article);


import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Icon, Input, Button, Checkbox, Upload, message, Card } from 'antd';
const props = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
class Profile extends Component {



  render() {
    return (
      <div className="profile-container">
        <Container>
          <Row>
            <Col>
            <h2 className='page-heading'>My Profile</h2>
              <Card>
                <Form className="profile-form">

                  <Row>
                    <Col md={4}>
                      <div className='profile-picture'>
                        <img src={require('../../img/login-art.png')} alt="" className='img-fluid' />
                      </div>
                    </Col>
                    <Col md={8}>
                      <Upload {...props} className="mt-5 d-block">
                        <Button type="primary">
                          <Icon type="upload" /> Click to Upload
                        </Button>
                      </Upload>
                    </Col>
                    <Col sm={12}>
                      <hr/>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Item label="First Name">
                        <Input placeholder="Enter First Name" />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item label="Last Name">
                        <Input placeholder="Enter Last Name" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Item label="Email">
                        <Input placeholder="Enter Email" />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item label="Phone Number">
                        <Input placeholder="Enter Phone Number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Item label="Address">
                        <Input placeholder="Enter Address" />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item label="Address 2">
                        <Input placeholder="Enter Address 2" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Item label="City">
                        <Input placeholder="Enter City" />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item label="State">
                        <Input placeholder="Enter State" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Item label="Country">
                        <Input placeholder="Enter Country" />
                      </Form.Item>
                    </Col>
                    <Col sm={6}>
                      <Form.Item label="Zip Code">
                        <Input placeholder="Enter Zip Code" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item className="gx-text-center">
                        <Button type="primary"  htmlType="submit">
                          Update
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>

      </div>);
  }
}



export default Profile;

import React from "react";
import {
  Pagination,
  Button,
  Modal,
  PageHeader,
  Form,
  Input,
  Icon,
  Spin,
  Select,
  DatePicker,
} from "antd";
import CustomModal from "../../../components/common/CustomModal";
import TextArea from "antd/lib/input/TextArea";
import ReactSwitch from 'react-switch';
import * as constant from "../../../actions/constant";
import {
  getSport,
  removeSport,
  addSport,
  resetGamePoints,
  sendSportMessage,
  setButton,
} from "../../admin/actions/adminActions";
import { connect } from "react-redux";
import { get } from "request";
import moment from "moment";
import toastr from "toastr";
const { confirm } = Modal;
const { Option } = Select;
const { RangePicker } = DatePicker;

class SportList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      totalRecord: 0,
      visible: false,
      sportData: [],
      sportId: "",
      sortValue: "",
      sortOrder: "",
      loading: true,
      seasonData: [],
      isSportUpdate: false,
      sportTypeData: [
        { id: 1, name: "Regular" },
        { id: 2, name: "Draw" },
      ],
      BonusData: [
        { id: 1, name: 'true' },
        { id: 2, name: 'false' },
      ],
      message: false,
      sportId: "",
      checked:false
    };
  }

  componentDidMount() {
    let obj = {
      page: this.state.currentPage,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
    };
    this.props.getSport(obj);
  }

  showModal = (id, name, description, type, startDate, endDate,stack,bonus) => {
    this.setState({
      visible: true,
      sportId: id,
    });
    if (id !== "") {
      this.props.form.resetFields();
      this.props.form.setFieldsValue({
        sportname: name,
        description: description,
        type: type,
        date: [moment(new Date(startDate)), moment(new Date(endDate))],
        Stackvalue:stack,
        bonus:`${bonus}`
      });
    } else {
      var sDate = new Date();
      sDate.setDate(sDate.getDate() + ((7 - sDate.getDay()) % 7) + 1);
      var eDate = new Date(sDate);
      eDate.setDate(eDate.getDate() + ((6 - eDate.getDay()) % 6) + 1);

      this.setState({
        isSportUpdate: false,
      });
      this.props.form.resetFields();
      // this.props.form.setFieldsValue({ date: [moment(new Date()), moment(new Date())] });
    }
  };

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      let obj = {
        sportname: values.sportname,
        description: values.description,
        sportId: this.state.sportId,
        type: values.type,
        startDate: values.date[0],
        endDate: values.date[1],
        stack:values.Stackvalue,
        bonus:values.bonus==='true'?true:false
      };
      console.log("obj",obj)
      this.setState({
        isSportUpdate: false,
      });
      // console.log("this  issssssss", obj);

      let endDate = new Date(values.date[1]);
      let current = new Date();

      if (current.getTime() <= endDate.getTime()) {
        this.setState({ visible: false });
        this.props.addSport(obj);
        this.props.form.resetFields();
      } else {
        toastr.warning(" The End Date should be longer than the Current Date ");
      }
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  showConfirm(sportId) {
    let self = this;
    let obj = {
      sportId: sportId,
    };
    confirm({
      title: constant.DELETE_RECORD,
      content: "",
      onOk() {
        self.props.removeSport(obj);
      },
      onCancel() { },
    });
  }

  showConfirmResetPoints(sportId) {
    let self = this;
    let obj = {
      params: {
        sportId: sportId,
      },
    };
    confirm({
      title: "Do you want to reset game points?",
      content: "",
      onOk() {
        self.props.resetGamePoints(obj);
      },
      onCancel() { },
    });
  }

  changePage(page, pageSize) {
    this.setState({
      currentPage: page - 1,
    });
    let obj = {
      page: page - 1,
      sortValue: this.state.sortValue,
      sortOrder: this.state.sortOrder,
    };
    this.props.getSport(obj);
  }

  sortData(sortVal) {
    this.setState({
      sortOrder: this.state.sortOrder === -1 ? 1 : -1,
    });
    let obj = {
      page: this.state.currentPage,
      sortValue: sortVal,
      sortOrder: this.state.sortOrder === -1 ? 1 : -1,
    };
    this.props.getSport(obj);
  }

  componentWillReceiveProps(nextProps) {
    let data = nextProps.sportData;
    this.setState({
      sportData: data,
      totalRecord: nextProps.totalCount,
      loading: false,
    });
  }

  handleMenuClick(values) {
    // console.log("values isss", values);
    let obj = {
      seasonId: values,
    };
    if (obj.seasonId == "") {
      let obj1 = {
        page: this.state.currentPage,
        sortValue: this.state.sortValue,
        sortOrder: this.state.sortOrder,
      };
      this.props.getSport(obj1);
    } else {
      this.props.getSport(obj);
    }
  }

  showModalMessage = (id, isMultiple) => {
    this.setState({
      message: true,
      sportId: id,
    });
    this.props.form.setFieldsValue({
      messageTitle: "",
      messageDescription: "",
    });
  };
  handleCancelMessage = () => {
    this.setState({ message: false, sportId: "" });
  };
  handleSendMessage = () => {
    this.props.form.validateFields((err, values) => {
      let obj = {
        title: values.messageTitle,
        message: values.messageDescription,
        sportId: this.state.sportId._id,
      };
      if (values.messageTitle == "" || values.messageDescription == "") {
        toastr.warning("Please Enter Data");
      } else {
        console.log("data ---->", obj);
        this.props.sendSportMessage(obj);
        this.setState({ message: false, sportId: "" });
      }
    });
  };


  handleChange(val){
let params={

  "sportId":val?._id,
  "isInviteCompButton":val.isInviteCompButton?!val.isInviteCompButton:true
}
      // console.log("value",val,params)

      // this.setState({ checked: val });
       this.props.setButton(params)
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let state = this.state;
    const sport = state.sportData.map((x, index) => (
      <tr key={index}>
        <td>{x.sportname}</td>
        <td>{x.startDate}</td>
        <td>{x.endDate}</td>
        <td>{x.type}</td>
        <td>{x.description}</td>
        <td>
          {x.isActive == null ? "" : x.isActive == true ? "Open" : "Closed"}
        </td>
        <td className="action-icons">
          <Button
            className=" btn-sm mr-2" type="link"
            onClick={this.showModal.bind(
              this,
              x._id,
              x.sportname,
              x.description,
              x.type,
              x.startDate,
              x.endDate,
              x.stack,
              x.bonus
            )}
          >
            <i className="fa fa-pencil"></i>
          </Button>
          <Button
            className="btn-sm mr-2" type="link"
            onClick={this.showConfirm.bind(this, x._id)}
          >
            <Icon type="delete" theme="filled" />
          </Button>
          <Button
            className="btn-sm mr-2" type="link"
            onClick={this.showModalMessage.bind(this, x, false)}
          >
            <i class="fa fa-paper-plane" aria-hidden="true"></i>
          </Button>
         
          

          {/* <Button className="btn btn-info btn-sm mr-2" onClick={this.showConfirmResetPoints.bind(this, x._id)}><i className="fa fa-anchor"></i></Button> */}
        </td>
        <td><ReactSwitch
             checked={x?.isInviteCompButton}
            // onChange={this.handleChange.bind(this,x._id)}
            onChange={val => this.handleChange(x)}
          /></td>
      </tr>
    ));

    return (
      <>
        {/* <h2 className="page-heading">Sport</h2> */}
        <div className="box box-default mb-4">
          <PageHeader
            title="Sport"
            subTitle=""
            extra={[
              <Button
                key="1"
                type="outline"
                onClick={this.showModal.bind(this, "", "", "")}
              >
                <Icon type="plus" className="mr-1" />
                <span> Add Sport</span>
              </Button>,
            ]}
          />
          <Spin spinning={this.state.loading} delay={500}>
            <div className="box-body">
              <div className='table-responsive'>
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        onClick={this.sortData.bind(this, "sportname")}
                        className="cursor-pointer"
                      >
                        Sport Name{" "}
                        {this.state.sortOrder === 1 ? (
                          <i
                            className="fa fa-sort-amount-asc"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-sort-amount-desc"
                            aria-hidden="true"
                          ></i>
                        )}
                      </th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Type</th>
                      <th scope="col">Description</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                      <th scope="col">Invite&Comp Button</th>
                    </tr>
                  </thead>
                  <tbody>{sport}</tbody>
                </table>
              </div>
              <Pagination
                style={{ textAlign: "right" }}
                pageSize={10}
                defaultCurrent={this.state.currentPage}
                total={this.state.totalRecord ? this.state.totalRecord : 1}
                onChange={this.changePage.bind(this)}
              />
              <CustomModal
                handleCancel={this.handleCancel}
                handleOk={this.handleOk}
                visible={this.state.visible}
                title="Sport"
                backBtnText="Back"
                submitBtnText="Submit"
              >
                <Form className="sport-form l_hgt0">
                  <Form.Item label="Sport Name">
                    {getFieldDecorator("sportname", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter sport",
                        },
                      ],
                    })(<Input placeholder="Sport" />)}
                  </Form.Item>
                  <Form.Item label="Description">
                    {getFieldDecorator("description", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter description",
                        },
                      ],
                    })(<Input placeholder="Description" />)}
                  </Form.Item>

                  <Form.Item label="Select start date and end date">
                    {getFieldDecorator("date", {
                      rules: [
                        { required: true, message: "Please enter date range" },
                      ],
                    })(
                      <RangePicker
                        disabled={this.state.isRoundUpdate}
                        placeholder="Date"
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Sport Type">
                    {getFieldDecorator("type", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter sport type ",
                        },
                      ],
                    })(
                      <Select
                        disabled={this.state.isSportUpdate}
                        placeholder="Sport Type"
                        onChange={this.handleMenuClick.bind(this)}
                      >
                        {this.state.sportTypeData.map((x, index) => {
                          return (
                            <Option value={x.name} key={index}>
                              {x.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="Round Bonus">
                    {getFieldDecorator("bonus", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please select  Bounus  type ",
                         
                        },
                      ],
                    })(
                      <Select
                    
                        placeholder="Bonus"
                      >
                        {this.state.BonusData.map((x, index) => {
                          return (
                            <Option value={x.name} key={index}>
                              {`${x.name}`}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item label="MULTI CALCULATOR STAKE VALUE">
                    {getFieldDecorator("Stackvalue", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter Stack value",
                        },
                      ],
                    })(<Input placeholder="stack Value" />)}
                  </Form.Item>
                </Form>
              </CustomModal>

              <CustomModal
                handleCancel={this.handleCancelMessage}
                handleOk={this.handleSendMessage}
                visible={this.state.message}
                title={this.state.sportId.sportname}
                backBtnText="Back"
                submitBtnText="Submit"
              >
                <Form className="team-form l_hgt0">
                  <Form.Item label="Message Title">
                    {getFieldDecorator("messageTitle", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter title",
                        },
                      ],
                    })(<Input placeholder="Title" />)}
                  </Form.Item>
                  <Form.Item label="Message Description">
                    {getFieldDecorator("messageDescription", {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter description",
                        },
                      ],
                    })(<TextArea placeholder="Description" />)}
                  </Form.Item>
                </Form>
              </CustomModal>
            </div>
          </Spin>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    sportData: state.admin.sportData,
    totalCount: state.admin.sportCount,
  };
}
const Sport = Form.create({ name: "sport" })(SportList);
export default connect(mapStateToProps, {
  getSport,
  addSport,
  removeSport,
  resetGamePoints,
  sendSportMessage,
  setButton
})(Sport);

import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import toptippers from '../img/toptippers.svg';


const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebaar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false
    };
    this.overlayhide = this.overlayhide
  }

  componentDidMount() {
    if (window.innerWidth < 645) {
      console.log('window.innerWidth', window.innerWidth);
      // this.setState((prev) => {
      //   console.log('prev.innerWidth', prev);
      //   return { sidebarVisible: true}
      // }  
      // );

      // this.setState({
      //   collapsed: true
      // }  
      // );

    }
    // else{
    //   this.setState((prev) => {
    //     console.log('prev.innerWidth', prev);
    //     return { sidebarVisible: false}
    //   }  
    //   );
    // }
  }



  render() {
    let path = this.props.location.pathname;
    console.log(this.props, 'propssssss', this.state)
    return (
      <>

        <Sider className={this.state.sidebarVisible ? 'mobileMenus' : ''} trigger={null} collapsible collapsed={this.props.collapsed}>
          <div className="logo">
            {/* <b>Tipping App</b> */}
            <img src={toptippers} alt="toptippers" />
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={[path]}>
            <Menu.Item key="/dashboard" onClick={() => this.props.history.push('/dashboard')}>
              <Icon style={{ verticalAlign: 0 }} type="dashboard" theme="filled" />
              <span>Dashboard</span>
            </Menu.Item>
            <Menu.Item key="/users" onClick={() => this.props.history.push('/users')}>
              {/* <Icon style={{ verticalAlign: 0 }} type="user" /> */}
              <svg width="18" height="22" className='mr-2' viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7866 6.53312C15.7866 10.0208 12.9902 12.8174 9.49998 12.8174C6.01099 12.8174 3.21338 10.0208 3.21338 6.53312C3.21338 3.0454 6.01099 0.25 9.49998 0.25C12.9902 0.25 15.7866 3.0454 15.7866 6.53312ZM9.5 24C4.34907 24 0 23.1628 0 19.9328C0 16.7016 4.37639 15.894 9.5 15.894C14.6521 15.894 19 16.7312 19 19.9613C19 23.1925 14.6236 24 9.5 24Z" fill="white" />
              </svg>

              <span>Manage User</span>
            </Menu.Item>
            <Menu.Item key="/messaging" onClick={() => this.props.history.push('/messaging')}>
              {/* <Icon style={{ verticalAlign: 0 }} type="mail" theme="filled"/> */}

              <i className="fa fa-envelope mr-2 " aria-hidden="true"></i>
              <i className='anticon d-none'></i>
              <span>Messaging</span>
            </Menu.Item>

            <SubMenu
              key="/sport"
              title={
                <span>
                  <Icon style={{ verticalAlign: 0 }} type="layout" theme="filled" />
                  <span>Master</span>
                </span>
              }
            >
              {/* <Menu.Item key="1" onClick={() => this.props.history.push('/season')} >Manage Season</Menu.Item> */}
              <Menu.Item key="1" onClick={() => this.props.history.push('/sport')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Manage Sport</Menu.Item>
              <Menu.Item key="2" onClick={() => this.props.history.push('/team')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Manage Team</Menu.Item>
              {/*<Menu.Item key="3" onClick={() => this.props.history.push('/bonus')} >Manage Bonus</Menu.Item>*/}
              <Menu.Item key="4" onClick={() => this.props.history.push('/round')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Manage Round</Menu.Item>
              <Menu.Item key="5" onClick={() => this.props.history.push('/game')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Manage Game</Menu.Item>
              <Menu.Item key="6" onClick={() => this.props.history.push('/competition')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Manage Competition</Menu.Item>

            </SubMenu>
            <Menu.Item key="/ladder" onClick={() => this.props.history.push('/ladder')}>
              <Icon style={{ verticalAlign: 0 }} type="mail" theme="filled"/>

              {/* <i className="fa fa-list mr-2 " aria-hidden="true"></i> */}
              <i className='anticon d-none'></i>
              <span>Article</span>
            </Menu.Item>
            <Menu.Item key="/banter" onClick={() => this.props.history.push('/banter')}>
              <Icon style={{ verticalAlign: 0 }} type="wechat" theme="filled"/>

              {/* <i className="fa fa-list mr-2 " aria-hidden="true"></i> */}
              <i className='anticon d-none'></i>
              <span>Banter</span>
            </Menu.Item>
            <SubMenu
              key="/cms"
              title={
                <span>
                  <Icon style={{ verticalAlign: 0 }} type="setting" theme="filled" />
                  <span>CMS</span>
                </span>
              }
            >
              {/* <Menu.Item key="1" onClick={() => this.props.history.push('/topics')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                FAQ</Menu.Item> */}
              <Menu.Item key="2" onClick={() => this.props.history.push('/questions')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                FAQs</Menu.Item>
              <Menu.Item key="3" onClick={() => this.props.history.push('/content')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Rules</Menu.Item>
            </SubMenu>
            <SubMenu
              key="/ad"
              title={
                <span>
                  <Icon style={{ verticalAlign: 0 }} type="sound" theme="filled" />
                  <span>Ad Management</span>
                </span>
              }
            >
              <Menu.Item key="1" onClick={() => this.props.history.push('/ads')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Ads</Menu.Item>
              <Menu.Item key="2" onClick={() => this.props.history.push('/adreport')} >
                <svg width="6" height="13" viewBox="0 0 8 13" fill="none" className='mr-2' xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Report</Menu.Item>

            </SubMenu>
            <Menu.Item key="/version" onClick={() => this.props.history.push('/version')}>
              <Icon style={{ verticalAlign: 0 }} type="info-circle" theme="filled" />
              <span>Manage Version</span>
            </Menu.Item>
            <Menu.Item key="/secretcomp" onClick={() => this.props.history.push('/secretcomp')}>
              <i className="fa fa-user-secret" aria-hidden="true"></i>
              <i className='anticon d-none'></i>
              <span> Secret Comp</span>
            </Menu.Item>
          </Menu>
          
          
        </Sider>
      </>
    );

  }
}

export default withRouter(Sidebaar);

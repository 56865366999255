import React, { Component } from 'react';
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import loginArtImage from '../../img/login-art.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import adminTip from '../../img/tips_blank.png'
import homeTip from "../../img/home.1.png"
import awayTip from "../../img/away.png"

import { LOGO_IMG_PATH, PROFILE_IMG_PATH } from '../../actions/utilAction';
class Tipdistribution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            selectedUserGroup: 'top 10',
            limit: "10"
        };
    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }
    handleUserGroupSelection = (limit) => {
        this.props.handeTippingCount(this.props.sportId, limit)
        this.setState({
            limit: limit
        });
    }

    render() {

        const { data } = this.props
        console.log("props", data)
        let limitData = [10, 20, 50, 100, 200, 300, 500]
        return (
            <>
                <div className='tipdistribution'>
                    <h2 className="page-heading">Tip Distribution</h2>
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                        <DropdownToggle caret>
                            User Group:Top {this.state.limit}
                        </DropdownToggle>
                        <DropdownMenu>
                            {limitData.map((num) => {
                                return (
                                    <DropdownItem onClick={() => this.handleUserGroupSelection(num)}>top {num}</DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                        <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                    </Dropdown>
                </div>
                { data&&data?.gameDetailAndTipping?.length>0?  data.gameDetailAndTipping.map((game, index) => {
                    const ImagePath=(url)=>{
                        if(url!==""){
                            return url
                        }
                        return homeTip
                    }
                    const tiippingCount=(team)=>{
                        let data=game?.tippingData.filter(ele=>ele._id===team)
                        return data[0]?data[0].count:0
                    }
                    let addTiiping =tiippingCount("home")+tiippingCount("away")
                    return(
                    <div>
                          <h5>{game?.homeTeam.teamname+" VS "+game?.awayTeam.teamname}</h5>
                        <div className="tip_container">
                            <div className="tip_inner">
                                <p>HOME</p>
                                <img src={ImagePath(game?.homeTeam.teamLogo)} alt="Image" />
                                <p>{tiippingCount("home") || 0} Tips</p>
                            </div>
                            <div className="tip_inner">
                                <p>AWAY</p>
                                <img src={ImagePath(game?.awayTeam.teamLogo)} alt="Image" />
                                <p>{tiippingCount("away") || 0} Tips</p>
                            </div>
                            <div className="tip_inner">
                                <p>NO TIP</p>
                                <img src={adminTip} alt="Image" />
                                <p>{ data.usercount-addTiiping|| 0} Tips</p>
                            </div>
                        </div>
                    </div>
                    )
                }):null}
            </>
        );
    }
}

export default Tipdistribution;

import React from 'react';
import { Switch, Checkbox, Pagination, Button, Modal, PageHeader, Row, Col, Form, Input, Spin, Select, DatePicker } from 'antd';
import CustomModal from '../../../components/common/CustomModal';
import * as constant from '../../../actions/constant';
import { getSport, getSeason, addSeason, removeSeason, blockSeason } from '../../admin/actions/adminActions';
import { connect } from 'react-redux';
import { API_URL } from '../../../actions/utilAction';
import toastr from 'toastr';
import {
    actionCreator,
    adminActionTypes,
    jsonApiHeader
} from '../../.././actions/utilAction';



import axios from 'axios';
const { confirm } = Modal;
const { RangePicker } = DatePicker;
let access_token = localStorage.access_token;

class SeasonList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            totalRecord: 0,
            visible: false,
            sportData: [],
            sportId: '',
            sortValue: '',
            sortOrder: '',
            loading: true,
            seasonData: [],
            selectedSport: [],
            checked: true
        };
    }

    componentDidMount() {
        let obj = {
            page: this.state.currentPage,
            sortValue: this.state.sortValue,
            sortOrder: this.state.sortOrder
        }

        let obj1 = {
            count: 1000
        }
        this.props.getSeason(obj);
        this.props.getSport(obj1);
    }

    showModal = (id, name, description, seasonId) => {
        this.setState({
            visible: true,
            sportId: id,
        });
        if (id !== "") {
            this.setState({
                isSportUpdate: true
            });
            this.props.form.setFieldsValue({ sportname: name, description: description, seasonId: seasonId })
        } else {
            this.props.form.resetFields();
        }
    };

    handleOk = () => {

        this.props.form.validateFields((err, values) => {
            let self = this;
            let obj = {
                seasonname: values.seasonname,
                startDate: values.date[0].format(),
                endDate: values.date[1].format(),
                sport: this.state.selectedSport
            };


            if (err) {
                confirm({
                    title: "Pleasse enter details",
                    onOk() { },
                    onCancel() { }
                })
            } else {
                if (values == "") {

                } else {
                    if (this.state.selectedSport == "") {
                        toastr.error("please Select a Sports")
                    } else {

                        confirm({
                            title: `Adding Season you can't editable so please confirm`,
                            onOk() {


                                console.log("obj isss", obj);
                                self.props.addSeason(obj);
                                self.setState({ visible: false });
                                self.props.form.resetFields()

                            },
                            onCancel() { }


                        })
                    }
                }
            }
        })
    };

    handleCancel = () => {
        this.setState({ visible: false });
        this.setState({
            selectedSport: []
        })
    };

    showConfirm(seasonId) {
        let self = this;
        let obj = {
            seasonId: seasonId
        }
        console.log(" data", obj);
        confirm({
            title: constant.DELETE_RECORD,
            content: '',
            onOk() {
                self.props.removeSeason(obj)
            },
            onCancel() { },
        });
    }

    changePage(page, pageSize) {
        this.setState({
            currentPage: page - 1,
        });
        let obj = {
            page: page - 1,
            sortValue: this.state.sortValue,
            sortOrder: this.state.sortOrder
        }
        this.props.getSport(obj)
    }

    sortData(sortVal) {
        this.setState({
            sortOrder: this.state.sortOrder === -1 ? 1 : -1
        });
        let obj = {
            page: this.state.currentPage,
            sortValue: sortVal,
            sortOrder: this.state.sortOrder === -1 ? 1 : -1
        }
        this.props.getSport(obj)
    }

    componentWillReceiveProps(nextProps) {
        let data = nextProps.sportData;
        this.setState({ sportData: data, loading: false })
        let sesData = nextProps.seasonData;
        this.setState({ seasonData: sesData, totalRecord: nextProps.totalCount, loading: false })
    }

    handleMenuClick(values) {
        // console.log("values isss", values);
        let obj = {
            "seasonId": values
        }
        if (obj.seasonId == '') {
            let obj1 = {
                page: this.state.currentPage,
                sortValue: this.state.sortValue,
                sortOrder: this.state.sortOrder
            }
            this.props.getSport(obj1);

        } else {
            this.props.getSport(obj);
        }
    }

    showSessionConfirm() {



        axios.get(API_URL + constant.Check, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.code === 200) {
                toastr.success(response.data.message)
                self.setState({
                    visible: true,
                });
            } else if (response.data.code === 201 || response.data.code === 400 || response.data.code == 409) {
                toastr.error(response.data.message)
            }
        });

        this.state.sportData.map((x, index) => this.state.selectedSport.includes(`${x._id}`) ? "" : this.state.selectedSport.push(x._id))

        let self = this;
        // confirm({
        //   title: "Are you sure you want to start new season?",
        //   content: "",
        //   onOk() {
        //     // self.props.clearSession();
        //     self.setState({
        //       modelVisible: true,
        //     });
        //   },
        // onCancel() { },
        // this.state.sportData.map(x => this.setState({ selectedSport: x.sportname }))

    }

    onChange = (e) => {
        console.log(`checked = ${e}`)
        // console.log(e.length == this.state.sportData.map((x, index) => x.sportname));
        // console.log(this.state.selectedSport);
    };

    blockSeason(seasonId) {
        let values = {
            seasonId: seasonId,
        }
        this.props.blockSeason(values);
        let obj = {
            page: this.state.currentPage,
            sortValue: this.state.sortValue,
            sortOrder: this.state.sortOrder
        }
        this.props.getSeason(obj);
    }

    onSelectSport = (e, name) => {
        console.log(name);

        if (this.state.selectedSport.includes(`${name}`)) {
            console.log("existed data");
            let list = this.state.selectedSport.filter((x, inde) => x !== `${name}`)

            this.setState({ selectedSport: list })
            // console.log("final datta  iss filter", list);
        } else {
            console.log("not Exist");
            this.state.selectedSport.push(name)

            let updated = []
            this.state.selectedSport.map((x, index) => updated.push(x))
            this.setState({
                selectedSport: updated
            })
            console.log("data=-=-=-==-=-=", this.state.selectedSport);

        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let state = this.state;

        // const sport = state.sportData.map(x => this.setState({ selectedSport: x.sportname }))

        const season = state.seasonData.map((x, index) => <tr key={index}>
            <td>{x.seasonname}</td>
            <td>{x.startDate}</td>
            <td>{x.endDate}</td>
            <td>{x.isActive == true ? "True" : x.isActive == null ? "" : "false"} </td>
            <td>{x.isActive == true ? "In progress" : x.isActive == null ? "" : "Completed"} </td>
            <td>
                {/* <Link
                    to={"/seasondetail/" + x._id}
                >
                    <Button className="btn btn-info btn-sm mr-2"><i className="fa fa-eye"></i></Button>
                </Link> */}
                {/* <Button className="btn btn-info btn-sm mr-2" onClick={this.showModal.bind(this, x._id, x.sportname, x.description, x.seasonId)}><i className="fa fa-pencil"></i></Button> */}
                <Button className="btn btn-info btn-sm mr-2" onClick={this.showConfirm.bind(this, x._id)}><i className="fa fa-trash"></i></Button>
                {/* <Button className="btn btn-info btn-sm mr-2" onClick={this.showConfirmResetPoints.bind(this, x._id)}><i className="fa fa-anchor"></i></Button> */}
            </td>
        </tr>);

        return (<>
            <div className="box box-default mb-4">
                <PageHeader
                    title="Season"
                    subTitle=""
                    extra={[
                        <Button
                            type="primary"
                            onClick={this.showSessionConfirm.bind(this)}
                            style={{
                                float: "right",
                            }}
                        >
                            Start New Season
                        </Button>
                    ]}
                />
                <Spin spinning={this.state.loading} delay={500}>
                    <div className="box-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className="cursor-pointer">Season Name {this.state.sortOrder === 1 ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>}</th>
                                    <th scope="col" className="cursor-pointer">Start Date </th>
                                    <th scope="col" className="cursor-pointer">End Date </th>
                                    <th scope="col" className="cursor-pointer">Is Current </th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {season}
                            </tbody>
                            {console.log("this is selected sports", this.state.selectedSport)}
                        </table>
                        {/* <Pagination style={{ 'textAlign': 'right' }} pageSize={5} total={this.state.totalRecord ? this.state.totalRecord : 1} onChange={this.changePage.bind(this)} /> */}
                        <CustomModal
                            handleCancel={this.handleCancel}
                            handleOk={this.handleOk}
                            visible={this.state.visible}
                            title="Season"
                            backBtnText="Back"
                            submitBtnText="Submit">
                            <Form className="sport-form l_hgt0">
                                <Form.Item label="Season Name">
                                    {getFieldDecorator('seasonname', {
                                        rules: [{ required: true, whitespace: true, message: 'Please enter Season name' }],

                                    })(
                                        <Input placeholder="Enter Season Name" />,
                                    )}
                                </Form.Item>

                                <Form.Item label="Select start date and end date">
                                    {getFieldDecorator("date", {
                                        rules: [
                                            { required: true, message: "Please enter date range" },
                                        ],
                                    })(
                                        <RangePicker
                                            disabled={this.state.isRoundUpdate}
                                            placeholder="Date"
                                        />
                                    )}
                                </Form.Item>
                                <Row>
                                    {
                                        this.state.sportData.map((x, index) => {
                                            return (

                                                <Col span={6}>
                                                    <label
                                                        className="control-label col-md-12 labelappt"
                                                        style={{ padding: "0px" }}
                                                        id="label_appt_11"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="appointmentfor"
                                                            name="AppointmentFor[]"
                                                            defaultValue={11}
                                                            key={x._id}
                                                            checked={
                                                                this.state.selectedSport.includes(`${x._id}`) ? true : false
                                                            }
                                                            onClick={(
                                                                e,
                                                                name = x._id
                                                            ) => {
                                                                this.onSelectSport(
                                                                    e,

                                                                    name
                                                                )
                                                            }
                                                            }
                                                        />
                                                        <b style={{ marginLeft: "10px" }} >
                                                            {x.sportname}
                                                        </b>
                                                    </label>
                                                </Col>
                                            )

                                        })
                                    }
                                </Row>

                            </Form>
                        </CustomModal>

                    </div>
                </Spin>
            </div >
        </>
        );
    }

}
function mapStateToProps(state) {
    return {
        sportData: state.admin.sportData,
        totalCount: state.admin.sportCount,
        seasonData: state.admin.seasonData
    };
}
const season = Form.create({ name: 'season' })(SeasonList);
export default connect(mapStateToProps, { getSport, getSeason, addSeason, removeSeason, blockSeason })(season);

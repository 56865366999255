import React from "react";
import {
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import loginArtImage from "../../img/login-art.png";
import { connect } from "react-redux";
import {
  getSport,
  getRound,
  GameListBYSportId,
  getladder,
  GamelistSecretComp,
  TpppingCount,
  getTeam,
} from "../admin/actions/adminActions";
import { LOGO_IMG_PATH, PROFILE_IMG_PATH } from "../../actions/utilAction";
import question from "../../img/ant-design_question.svg";
import circle from "../../img/cil_check-circle.svg";
import close from "../../img/close-circle-outline.svg";
import adminTip from "../../img/tips_blank.png";
import Tipdistribution from "./Tipdistribution";
import { Spin, Pagination } from "antd";
import user from "../../img/user_placeholder.png";
class SecretComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sportDropdownOpen: false,
      roundDropdownOpen: false,
      filterDropdownOpen: false,
      selectedSport: "",
      selectedFilter: "All Teams",
      sportData: [],
      gameData: [],
      ladderData: [],
      secretCompGameDate: [],
      sportId: null,
      teamId: null,
      TippingCount: {},
      loading: true,
      teamData: [],
      gameName: "",
      roundList: [],
      selectedRoundID: "",
      selectedRoundName: "",
      currentPage: 0,
      totalPage: 0,
      limit: 30,
    };
  }
  toggleSportDropdown = () => {
    this.setState((prevState) => ({
      sportDropdownOpen: !prevState.sportDropdownOpen,
    }));
  };
  toggleFilterDropdown = () => {
    this.setState((prevState) => ({
      filterDropdownOpen: !prevState.filterDropdownOpen,
    }));
  };
  toggleRoundDropdown = () => {
    this.setState((prevState) => ({
      roundDropdownOpen: !prevState.roundDropdownOpen,
    }));
  };
  //on sport selection
  handleSportSelection = (sport, _id) => {
    this.setState({
      selectedSport: sport,
      sportId: _id,
      selectedFilter: "All Game",
    });
    this.handelTeam(_id);
    this.handelRound(_id);
  };
  async handelRound(id) {
    try {
      const obj = {
        sportId: id,
        sortValue: "createdAt",
        sortOrder: 1,
        count: 50,
      };

      const res = await this.props.getRound(obj);
      console.log("ress", res);
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
      console.log(
        "🚀 ~ SecretComp ~ handelRound ~ formattedDate:",
        formattedDate
      );
      const currentRound = res.data.filter((ele) => {
        // Extract the UTC date portion of startDate and endDate
        const startDateUtcDate = new Date(ele.startDate)
          .toISOString()
          .split("T")[0];
        const endDateUtcDate = new Date(ele.endDate)
          .toISOString()
          .split("T")[0];

        // Extract the UTC date portion of formattedDate
        const formattedDateUtcDate = new Date(formattedDate)
          .toISOString()
          .split("T")[0];

        console.log(
          "🚀 ~ SecretComp ~ currentRound ~ startDateUtcDate:",
          startDateUtcDate,
          endDateUtcDate
        );

        // Compare only the UTC date portion
        return (
          startDateUtcDate <= formattedDateUtcDate &&
          formattedDateUtcDate <= endDateUtcDate
        );
      });

      const data = currentRound[0];
      if (data) {
        this.setState({
          roundList: res.data,
          selectedRoundID: data._id,
          selectedRoundName: data?.roundname,
        });
        await this.handelgameDate(id, "", data._id);
        await this.handeTippingCount(id, data._id);
      }
      console.log("🚀 ~ SecretComp ~ handelRound ~ data:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  handelSelectRound(round) {
    this.setState({
      selectedRoundID: round?._id,
      selectedRoundName: round?.roundname,
    });
    this.handelgameDate(this.state.sportId, "", round?._id);
    this.handeTippingCount(this.state.sportId, round?._id);
  }
  //handel team selection
  handleFilterSelection = (filter, id) => {
    this.setState({
      selectedFilter: filter,
      teamId: id,
    });
    this.handelgameDate(this.state.sportId, id, this.state.selectedRoundID);
  };
  //get Point Ladder and game data
  handelgameDate = async (sportID, teamId, roundID, pagination) => {
    this.setState({ loading: true });
    try {
      const res = await this.props.GamelistSecretComp({
        round: roundID,
        sport: sportID,
        teamId,
        ...pagination,
        limit: this.state.limit,
      });
      console.log("responceeeee", res);
      this.setState({
        secretCompGameDate: res.docs,
        currentPage: res.page,
        totalPage: res.totalPages,
        loading: false,
      });
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  };

  //get tiipping count
  handeTippingCount = async (sport, round, limit) => {
    this.setState({ loading: true });
    try {
      const res = await this.props.TpppingCount({ sport, round, limit });
      this.setState({ TippingCount: res, loading: false });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  };

  //get team list using sport id
  handelTeam(sportId) {
    let obj1 = {
      sport: sportId,
      count: 1000,
    };
    this.props.getTeam(obj1);
  }
  async componentDidMount() {
    try {
      const obj1 = {
        count: 1000,
        isActive: true,
      };
      const sportData = await this.props.getSport(obj1);
      if (sportData && sportData.length > 0) {
        const selectedSport = sportData[0]?.sportname;

        this.setState({
          sportData,
          loading: false,
          sportId: sportData[0]?._id,
          selectedSport,
        });
        this.handelTeam(sportData[0]?._id);
        this.handelRound(sportData[0]?._id);
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  }
  changePage(page, pageSize) {
    this.setState({
      currentPage: page,
    });
    let obj = {
      page: page,
    };
    this.handelgameDate(
      this.state.sportId,
      this.state.teamId,
      this.state.selectedRoundID,
      obj
    );
  }
  componentWillReceiveProps(nextProps) {
    let data = nextProps.teamData;
    this.setState({
      teamData: data,
      totalRecord: nextProps.totalCount,
      loading: false,
    });
  }
  render() {
    return (
      <>
        <h2 className="page-heading">SecretComp</h2>
        <div className="box box-default mb-4">
          <Spin spinning={this.state.loading} delay={500}>
            <div className="box-body secret_comp_main">
              <div className="table-responsive secret_comp">
                <Table>
                  <thead>
                    <tr>
                      <th>
                        Sport:
                        <Dropdown
                          isOpen={this.state.sportDropdownOpen}
                          toggle={this.toggleSportDropdown}
                        >
                          <DropdownToggle caret>
                            {this.state.selectedSport}
                          </DropdownToggle>
                          <DropdownMenu>
                            {this.state.sportData.map((sport, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() =>
                                  this.handleSportSelection(
                                    sport?.sportname,
                                    sport?._id
                                  )
                                }
                              >
                                {sport?.sportname}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </th>
                      <th>
                        Round:
                        <Dropdown
                          isOpen={this.state.roundDropdownOpen}
                          toggle={this.toggleRoundDropdown}
                        >
                          <DropdownToggle caret>
                            {this.state.selectedRoundName}
                          </DropdownToggle>
                          <DropdownMenu>
                            {this.state.roundList.map((round, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => this.handelSelectRound(round)}
                              >
                                {round?.roundname}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </th>
                      <th>
                        Teams:
                        <Dropdown
                          isOpen={this.state.filterDropdownOpen}
                          toggle={this.toggleFilterDropdown}
                        >
                          <DropdownToggle caret>
                            {this.state.selectedFilter}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() =>
                                this.handleFilterSelection("All Games", "")
                              }
                            >
                              {" "}
                              All Games{" "}
                            </DropdownItem>
                            {this.state.teamData.map((team, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() =>
                                  this.handleFilterSelection(
                                    team?.teamname,
                                    team?._id
                                  )
                                }
                              >
                                {team?.teamname}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </th>
                      <th>
                        Active users: {this.state.TippingCount?.usercount || 0}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="4" className="ladder">
                        <span>Ladder</span>
                        <span className="reveal">Reveal</span>
                        <table className="inner-table text-left">
                          <thead>
                            <tr>
                              <th colSpan={2}>User</th>
                              <th colSpan={1}>Rank</th>
                              <th colSpan={1}>Round Point</th>
                              <th colSpan={1}>Session Point</th>
                              {this.state.secretCompGameDate[0]?.games.map(
                                (ele, i) => {
                                  return <th>{i + 1}</th>;
                                }
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.secretCompGameDate.map(
                              (data, index) => {
                                let rank =
                                  index +
                                  (this.state.currentPage - 1) *
                                    this.state.limit;
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        src={
                                          data?.profilePhoto
                                            ? PROFILE_IMG_PATH +
                                              data?.profilePhoto
                                            : user
                                        }
                                      />
                                    </td>
                                    <td>{data?.name}</td>
                                    <td>{rank + 1}</td>
                                    <td>{data?.roundpoint.toFixed(2)}</td>
                                    <td>{data?.seasonPoints}</td>
                                    {data.games.map((ele, i) => {
                                      const imgLogo = (ele) => {
                                        if (
                                          ele?.gameState === "started" ||
                                          ele?.gameState === "finished" ||
                                          ele?.gameState === "open"
                                        ) {
                                          let obj1 = {
                                            home: ele?.homeTeam?.teamLogo,
                                            away: ele?.awayTeam?.teamLogo,
                                          };
                                          return ele?.tippingAddedBy === "admin"
                                            ? adminTip
                                            : obj1[ele.tipping];
                                        } else {
                                          return question;
                                        }
                                      };

                                      let winingLogo =
                                        ele?.winningTeam?.toLowerCase() ===
                                        ele?.tipping?.toLowerCase();

                                      return (
                                        <td className="image_flag">
                                          <img src={imgLogo(ele) || question} />
                                          <span>
                                            {ele.winningTeam !== "" ? (
                                              <img
                                                src={
                                                  winingLogo ? circle : close
                                                }
                                              />
                                            ) : null}
                                          </span>
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Pagination
                  style={{ textAlign: "right" }}
                  pageSize={this.state.limit}
                  defaultCurrent={this.state.currentPage}
                  total={this.state.TippingCount?.usercount || 0}
                  onChange={this.changePage.bind(this)}
                />
              </div>
              <Tipdistribution
                data={this.state.TippingCount}
                gameName={this.state.gameName}
                sportId={this.state.sportId}
                handeTippingCount={this.handeTippingCount}
              />
            </div>
          </Spin>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    totalCount: state.admin.roundCount,
    sportData: state.admin.sportData,
    gameData: state.admin.gameData,
    ladderData: state.admin.ladderData,
    secretCompGameDate: state.admin.secretCompGameDate,
    teamData: state.admin.teamData,
  };
}
export default connect(mapStateToProps, {
  getSport,
  GameListBYSportId,
  getladder,
  GamelistSecretComp,
  TpppingCount,
  getTeam,
  getRound,
})(SecretComp);

// export default SecretComp;
